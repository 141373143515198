import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}

const routes = [
  {
    path: '/',
    redirect: '/main',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/index.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register/index.vue'),
    children: [
      { path: '/', redirect: '/register/email' },
      {
        path: 'email/:id?',
        name: 'Email',
        meta: { sort: 1, name: 'Identity' },
        component: () => import('../views/register/email.vue'),
      },
      {
        path: 'identity',
        name: 'Identity',
        meta: { sort: 2 },
        component: () => import('../views/register/identity.vue'),
      },
      {
        path: 'mainlandUser',
        name: 'MainlandUser',
        meta: { sort: 3, name: 'IdentityVerification' },
        component: () => import('../views/register/mainlandUser.vue'),
      },
      {
        path: 'identityVerification',
        name: 'IdentityVerification',
        meta: { sort: 4, name: 'BankInfo' },
        component: () => import('../views/register/identityVerification.vue'),
      },
      {
        path: 'bankInfo',
        name: 'BankInfo',
        meta: { sort: 6, name: 'Limit' },
        component: () => import('../views/register/bankInfo.vue'),
      },
      {
        path: 'overseasUser',
        name: 'OverseasUser',
        meta: { sort: 3, name: 'IdentityVerification' },
        component: () => import('../views/register/overseasUser.vue'),
      },
      {
        path: 'organizationUser',
        name: 'OrganizationUser',
        meta: { sort: 3, name: 'IdentityVerification' },
        component: () => import('../views/register/organizationUser.vue'),
      },
      {
        path: 'limit',
        name: 'Limit',
        meta: { sort: 7, name: 'SetPassword' },
        component: () => import('../views/register/limit.vue'),
      },
      {
        path: 'setPassword',
        name: 'SetPassword',
        meta: { sort: 8, name: 'SetPayPass' },
        component: () => import('../views/register/setPassword.vue'),
      },
      {
        path: 'setPayPass',
        name: 'SetPayPass',
        meta: { sort: 9, name: 'ApplySucceed' },
        component: () => import('../views/register/setPayPass.vue'),
      },
      {
        path: 'applySucceed',
        name: 'ApplySucceed',
        meta: { sort: 10, name: 'ApplySucceed' },
        component: () => import('../views/register/applySucceed.vue'),
      },
      {
        path: 'forgetPassword',
        name: 'ForgetPassword',
        component: () => import('../views/register/forgetPassword.vue'),
      },
      {
        path: 'findPassword',
        name: 'FindPassword',
        component: () => import('../views/register/findPassword.vue'),
      },
      {
        path: 'emailVerify',
        name: 'emailVerify',
        component: () => import('../views/register/emailVerify.vue'),
      },
    ],
  },
  {
    path: '/h5/guidance',
    name: 'Guidance',
    component: () => import('../views/h5/guidance.vue'),
  },
  {
    path: '/h5/uploading/:email',
    name: 'Uploading',
    meta: { sort: 5, name: 'BankInfo' },
    component: () => import('../views/h5/uploading.vue'),
  },
  {
    path: '/main',
    name: 'Main',
    component: () => import('../views/main/index.vue'),
    children: [
      { path: '/', redirect: '/main/home' },
      {
        path: 'home',
        name: 'Home',
        component: () => import('../views/home/index.vue'),
      },
      {
        path: 'contact',
        name: 'Contact',
        component: () => import('../views/contact/index.vue'),
      },
      {
        path: 'about',
        name: 'About',
        component: () => import('../views/about/index.vue'),
      },
      {
        path: 'faq',
        name: 'FAQ',
        component: () => import('../views/faq/index.vue'),
      },
      {
        path: 'conversion',
        name: 'Conversion',
        component: () => import('../views/conversion/index.vue'),
      },
      {
        path: 'enhance',
        name: 'Enhance',
        component: () => import('../views/conversion/enhance.vue'),
      },
      {
        path: 'account',
        name: 'Account',
        component: () => import('../views/index/account.vue'),
      },
      {
        path: 'card',
        name: 'Card',
        component: () => import('../views/index/card.vue'),
      },
      {
        path: 'exchange',
        name: 'Exchange',
        component: () => import('../views/index/exchange.vue'),
      },
      {
        path: 'help',
        name: 'Help',
        component: () => import('../views/index/help.vue'),
      },
      {
        path: 'payment',
        name: 'Payment',
        component: () => import('../views/index/payment.vue'),
      },
      {
        path: 'pricing',
        name: 'Pricing',
        component: () => import('../views/index/pricing.vue'),
      },
      {
        path: 'privacyPolicy',
        name: 'PrivacyPolicy',
        component: () => import('../views/index/privacyPolicy.vue'),
      },
      {
        path: 'my/:to?',
        name: 'My',
        component: () => import('../views/my/index.vue'),
        // children: [
        //   { path: '/', redirect: '/main/my/user' },
        //   {
        //     path: 'user',
        //     name: 'User',
        //     component: () => import('../views/my/user/index.vue'),
        //   },
        //   {
        //     path: 'limit',
        //     name: 'MyLimit',
        //     component: () => import('../views/my/limit/index.vue'),
        //   },
        //   {
        //     path: 'walletAddr',
        //     name: 'WalletAddr',
        //     component: () => import('../views/my/walletAddr.vue'),
        //   },
        //   {
        //     path: 'bankCard',
        //     name: 'BankCard',
        //     component: () => import('../views/my/bankCard/bankCard.vue'),
        //   },
        //   {
        //     path: 'invitation',
        //     name: 'Invitation',
        //     component: () => import('../views/my/invitation.vue'),
        //   },
        // ],
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
