import zhTWLocale from 'element-ui/lib/locale/lang/zh-TW' //引入element语言包
const zhTW = {
  header_1: '首頁',
  header_2: '銀行卡片',
  header_3: '關於公司',
  header_4: '常見問題',
  header_5: '登陸',
  header_6: '註冊',
  header_7: '退出',
  footer_1: '我是公司名字',
  footer_2: '忠告：請務必償還貸款, 及不要支付任何中介費用',
  footer_3: '功能',
  footer_4: '賬戶',
  footer_5: '匯款',
  footer_6: '貨幣兌換',
  footer_7: '幫助中心',
  footer_8: '聯系我們',
  footer_9: '關於我們',
  footer_10: '隱私政策',
  footer_11: '資源',
  footer_12: '公司',
  footer_13: '忠告：請務必償還貸款, 及不要支付任何中介費用',
  index_banner_1: '一個賬戶,通全球',
  index_banner_2:
    '一站式簡化跨境支付：提供多種幣種賬戶,商業visa卡,國際匯款和換匯',
  index_banner_3: '立即開始',
  index_banner_4:
    '非常簡單的用戶界面、快速打開戶、低費用、服務優質、我非常推薦',
  index_banner_5: '快速、高效和專業的客戶服務,極力推薦',
  index_banner_6: '領先於銀行行業多年的數字金融服務',
  index_business_1: '滿足各種商業務求的解決方案',
  index_business_2: '賬戶',
  index_business_3: '無處不在',
  index_business_4:
    '需要銀行賬號嗎？ 只需在您的全球賬戶中選擇一個地區,我們就會在當地合作銀行為您開立一個專屬於您的虛擬賬戶號碼。 您收到的所有資金可以直接存入您的多幣種錢包。',
  index_business_5: '立即申請',
  index_bank_1: '匯款',
  index_bank_2:
    '全球輕松收付款。在全國多個國家以多種貨幣收付款,盡顯本地優勢。管理賬戶更加靈活、快捷、優惠。',
  index_bank_3: 'Metabank卡',
  index_bank_4: '隨時隨地,自在消費',
  index_bank_5:
    '通過Metabank卡,使用您的全球賬戶資金進行網上和線下支付。在全球支持Visa的商戶網絡讓您輕松支付任何費用。',
  index_bank_6: '立即申請',
  index_conversion_1: '貨幣兌換',
  index_conversion_2: '簡單透明,輕松搞定',
  index_conversion_3:
    '就像在XE.com或者路透社上一樣,您會獲得真正的中端市場匯率。兌換貨幣僅需支付最優惠的費用,因此您無需花費時間比較。',
  index_conversion_4: '立即申請',
  index_issue_1: '常見問題解答',
  index_issue_2: '問題列表',
  index_issue_3: '更多',
  index_con_1: '聯系我們',
  faq_1: '常見問題解答',
  faq_2: '了解更多',
  faq_3: '常見問題',
  login_1: '請輸入郵箱地址',
  login_2: '請輸入正確的郵箱地址',
  login_3: '郵箱',
  login_4: '請輸入密碼',
  login_5: '密碼',
  login_6: '沒有賬戶？立即註冊賬戶',
  login_7: '忘記密碼？',
  login_8: '登錄',
  login_9: '我已閱讀並同意',
  login_10: '《隱私政策》',
  login_11: '需要幫助？快聯系我們',

  reg_email_1: '讓我們開始吧',
  reg_email_2:
    '申請免費企業帳戶的程序將需要10到15分鐘。 開始後,您可以保存進度並隨時回來繼續申請。',
  reg_email_3:
    '申請程序應由貴公司的授權代表完成。 您將需要為所有公司董事和主要股東提供商業註冊文件,個人身份證明文件和個人地址證明。',
  reg_email_4:
    '《財務條例》規定,Metabank必須符合「了解您的客戶」(KYC)的要求,「反洗錢」(AML)和「反恐融資」(CFT)條例的規定。',
  reg_email_5:
    '因此,提供完整,清晰和準確的業務資料（包括財務交易）非常重要。 信息不完整或不一致可能會導致您的企業帳戶申請延遲,甚至被拒絕申請。我們期待為您服務。',
  reg_email_6: '電子郵箱：',
  reg_email_7: '請輸入郵箱地址',
  reg_email_8: '請輸入正確的郵箱地址',
  reg_email_9: '請輸入郵箱地址',
  reg_email_10: '立即提交',
  reg_email_11: '已有賬號,立即登錄',
  reg_email_12: '已將驗證鏈接發送至電子郵件',
  reg_identity_1: '請選擇您的註冊身份',
  reg_identity_2: '中國大陸外個人用戶',
  reg_identity_3: '機構用戶',
  reg_identity_4: '中國大陸個人用戶',
  reg_identity_5: '註：一旦註冊成功,身份將不可更改',
  reg_identity_6: '繼續',
  reg_identity_7: '返回',
  reg_mainLandUser_1: '個人詳情',
  reg_mainLandUser_2: '您的名稱：',
  reg_mainLandUser_3: '請輸入您的名稱',
  reg_mainLandUser_4: '請輸入您的名稱',
  reg_mainLandUser_5: '您的姓氏：',
  reg_mainLandUser_6: '請輸入您的姓氏',
  reg_mainLandUser_7: '請輸入您的姓氏',
  reg_mainLandUser_8: '手機號碼：',
  reg_mainLandUser_9: '請輸入您的手機號碼',
  reg_mainLandUser_10: '請輸入您的手機號碼',
  reg_mainLandUser_11: '繼續',
  reg_mainLandUser_12: '返回',
  reg_identityVerification_1: '身份驗證',
  reg_identityVerification_2: '請用您的手機掃描下方二維碼',
  reg_identityVerification_3: '教程',
  reg_identityVerification_4:
    '手機攝像頭對準二維碼如果無法運行,可以從谷歌Play或App Store下載一個二維碼掃描器',
  reg_identityVerification_5: '掃描二維碼的方法',
  reg_identityVerification_6: '繼續',
  reg_identityVerification_7: '返回',

  reg_guidance_1: '移動會話鏈接到您的計算機',
  reg_guidance_2: '繼續驗證',
  reg_guidance_3: '再次確認',
  reg_guidance_4: '此鏈接由您發送 —— 如果您認為這可能是騙局,請尋求幫助',
  reg_guidance_5: '您的桌面窗口保持打開狀態',
  reg_uploading_1: '護照信息上傳',
  reg_uploading_2: '簽發國家：',
  reg_uploading_3: '請選擇簽發國家',
  reg_uploading_4: '請選擇簽發國家',
  reg_uploading_5: '護照號碼：',
  reg_uploading_6: '請輸入護照號碼',
  reg_uploading_7: '請輸入護照號碼',
  reg_uploading_8: '護照封面照：',
  reg_uploading_9: '請選擇護照封面照',
  reg_uploading_10: '護照首頁照：',
  reg_uploading_11: '請選擇護照首頁照',
  reg_uploading_12: '手持護照首頁照：',
  reg_uploading_13: '請選擇手持護照首頁照',
  reg_uploading_14: '提交',
  reg_uploading_15: '請選擇簽發國家',
  reg_affirm_1: '確保卡上信息清晰可讀、不模糊 、無反光',
  reg_affirm_2: '重新上傳',
  reg_affirm_3: '確認',
  reg_bankInfo_1: '銀行資料提交',
  reg_bankInfo_2: '地址證明(必須為3個月內發出)',
  reg_bankInfo_3: '說明',
  reg_bankInfo_4:
    '此資料為後續製作銀行卡使用,有效地址證明為三個月內的住址水電賬單,或房屋產權證明文件。',
  reg_bankInfo_5: '上傳文件',
  reg_bankInfo_6: '支持擴展名',
  reg_bankInfo_7: '繼續',
  reg_bankInfo_8: '一鍵同步提交',
  reg_bankInfo_9: '返回',
  reg_limit_1: '初始兌換額度',
  reg_limit_2: '您目前的身份為',
  reg_limit_3: '您的初始年度總兌換額度為',
  reg_limit_4: '如需申請額外額度,請點擊「額外申請」',
  reg_limit_5: '繼續',
  reg_limit_6: '返回',

  reg_setPassword_1: '設置密碼',
  reg_setPassword_2: '您的密碼長度至少應為八(8)位數,並包含至少一(1)個以下字符',
  reg_setPassword_3: '大寫字母',
  reg_setPassword_4: '小寫字母',
  reg_setPassword_5: '數字',
  reg_setPassword_6: '特殊字符',
  reg_setPassword_7: '密碼',
  reg_setPassword_8: '請輸入密碼',
  reg_setPassword_9: '確認密碼',
  reg_setPassword_10: '請再次輸入密碼',
  reg_setPassword_11: '繼續',
  reg_setPassword_12: '返回',
  reg_setPassword_13: '已有賬號,立即登錄',
  reg_setPassword_14: '請輸入密碼',
  reg_setPassword_15: '密碼格式不正確',
  reg_setPassword_16: '請再次輸入密碼',
  reg_setPassword_17: '兩次輸入密碼不一致',
  reg_setPayPass_1: '設置支付密碼',
  reg_setPayPass_2: '您的密碼長度至少應為八(8)位數,並包含至少一(1)個以下字符',
  reg_setPayPass_3: '大寫字母',
  reg_setPayPass_4: '小寫字母',
  reg_setPayPass_5: '數字',
  reg_setPayPass_6: '特殊字符',
  reg_setPayPass_7: '支付密碼',
  reg_setPayPass_8: '請輸入密碼',
  reg_setPayPass_9: '確認密碼',
  reg_setPayPass_10: '請再次輸入密碼',
  reg_setPayPass_11: '繼續',
  reg_setPayPass_12: '返回',
  reg_applySuccess_1: '申請成功',
  reg_applySuccess_2: '請立即支付150U開戶費用 ,便於為您開戶 。',
  reg_applySuccess_3: '立即支付',
  reg_applySuccess_4: '請等待工作人員審核',
  reg_applySuccess_5: '立即支付',
  reg_applySuccess_6: '錢包地址：',
  reg_applySuccess_7: '收款碼：',
  reg_applySuccess_8: '哈希值：',
  reg_applySuccess_9: '請輸入付款哈希值信息',
  reg_applySuccess_10: '請輸入付款哈希值信息',
  reg_applySuccess_11:
    '請使用自己的錢包轉賬,此付款錢包將自動保存為您的常用錢包地址。',
  reg_applySuccess_12: '取消',
  reg_applySuccess_13: '已支付',
  reg_findPassword_1: '找回密碼',
  reg_findPassword_2: '您的密碼長度至少應為八(8)位數,並包含至少一(1)個以下字符',
  reg_findPassword_3: '大寫字母',
  reg_findPassword_4: '小寫字母',
  reg_findPassword_5: '數字',
  reg_findPassword_6: '特殊字符',
  reg_findPassword_7: '密碼',
  reg_findPassword_8: '請輸入密碼',
  reg_findPassword_9: '確認密碼',
  reg_findPassword_10: '請再次輸入密碼',
  reg_findPassword_11: '立即重置',
  reg_forgetPassword_1: '忘記密碼',
  reg_forgetPassword_2: '我們將通過電子郵箱向您發送重置密碼說明',
  reg_forgetPassword_3: '電子郵箱：',
  reg_forgetPassword_4: '請輸入郵箱地址',
  reg_forgetPassword_5: '請輸入正確的郵箱地址',
  reg_forgetPassword_6: '請輸入郵箱地址',
  reg_forgetPassword_7: '立即提交',
  reg_forgetPassword_8: '返回',
  reg_forgetPassword_9: '已將驗證鏈接發送電子郵件至',
  reg_organizationUser_1: '機構詳情',
  reg_organizationUser_2: '機構名稱：',
  reg_organizationUser_3: '請輸入機構名稱',
  reg_organizationUser_4: '請輸入機構名稱',
  reg_organizationUser_5: '法人名稱：',
  reg_organizationUser_6: '請輸入法人名稱',
  reg_organizationUser_7: '請輸入法人名稱',
  reg_organizationUser_8: '法人姓氏：',
  reg_organizationUser_9: '請輸入法人姓氏',
  reg_organizationUser_10: '請輸入法人姓氏',
  reg_organizationUser_11: '聯系方式：',
  reg_organizationUser_12: '請輸入您的手機號碼',
  reg_organizationUser_13: '請輸入您的手機號碼',
  reg_organizationUser_14: '機構註冊頁',
  reg_organizationUser_15: '說明',
  reg_organizationUser_16: '最近3個月內的水電費或手機/銀行卡/銀行結單。',
  reg_organizationUser_17: '上傳文件',
  reg_organizationUser_18: '支持擴展名',
  reg_organizationUser_19: '繼續',
  reg_organizationUser_20: '返回',
  reg_overseasUser_1: '海外註冊詳情',
  reg_overseasUser_2: '您的名稱：',
  reg_overseasUser_3: '請輸入您的名稱',
  reg_overseasUser_4: '請輸入您的名稱',
  reg_overseasUser_5: '您的姓氏：',
  reg_overseasUser_6: '請輸入您的姓氏',
  reg_overseasUser_7: '請輸入您的姓氏',
  reg_overseasUser_8: '手機號碼：',
  reg_overseasUser_9: '請輸入您的手機號碼',
  reg_overseasUser_10: '請選擇',
  reg_overseasUser_11: '請輸入您的手機號碼',
  reg_overseasUser_12: '地址證明(必須為3個月內發出)',
  reg_overseasUser_13: '說明',
  reg_overseasUser_14: '最近3個月內的水電費或手機/銀行卡/銀行結單。',
  reg_overseasUser_15: '上傳文件',
  reg_overseasUser_16: '支持擴展名',
  reg_overseasUser_17: '繼續',
  reg_overseasUser_18: '返回',

  main_account_1: '跨境商戶賬戶',
  main_account_2: '註冊',
  main_account_3: '以更輕松便捷的方式收付款和兌換外幣。',
  main_account_4:
    '全球輕松收付款。 在全球多個國家以多種貨幣收付款,始終擁有本地優勢。此外,您還可以享受真正的跨境交易零匯損和使用Visa卡,以滿足擴展全球業務的一切需求。',
  main_account_5: '外幣帳戶讓您隨時走向國際。',
  main_account_6:
    '在香港、澳大利亞、加拿大、歐洲、印尼、日本、墨西哥、新加坡、英國和美國開設銀行賬號,我們還將增加更多國家。',
  main_account_7: '新一代金融平臺。',
  main_account_8:
    '可通過網絡和應用程序實現全天候訪問,獲得支付和信貸引擎的強大支持,收費透明相宜,是您收款、兌換外幣和轉賬的不二選擇。',
  main_payment_1: '無論在何地營商都可享本地待遇。',
  main_payment_2: '註冊',
  main_payment_3: '數字經濟支付方式。',
  main_payment_4:
    '如今,企業可輕松實現全球買賣交易,因此,我們必須讓企業能夠同樣輕松實現全球收付款。使用全球帳戶進行支付,讓您能夠在所選擇的國家開設新的外幣帳戶,並為您提供專屬的銀行賬號。',
  main_payment_5: '更實惠,更快捷,更安全。',
  main_payment_6:
    '我們支持多達14種收款幣種和18種付款幣種。無縫跨境支付,不收取代理行費用。此外,您還可以享受真正的中間價。',
  main_payment_7: '真正實現「覆蓋全球」。',
  main_payment_8:
    '通過覆蓋30多個國家的虛擬帳戶可實現以美元、英鎊、歐元和其他國際貨幣收款。將帳戶與亞馬遜和Shopify等電商平臺連接,或與Stripe和PayPal等第三方支付連接。',
  main_payment_9: '支付直通車。',
  main_payment_10:
    '以您的香港多幣種帳戶收取匯款,並將款項匯給100多個國家的收款人。',
  main_card_1: '為您帶來極盡靈活的理財體驗。',
  main_card_2: '立即註冊',
  main_card_3: '您的安心之選。',
  main_card_4: '即時批核',
  main_card_5:
    '僅需點擊 6 次,全球賬戶客戶即可獲得一張新的虛擬卡！立即開始在線消費。',
  main_card_6: '高度安全',
  main_card_7:
    '付款卡的敏感信息將安全地存儲在Currenxie應用程序中。 只有您可以獲取這些信息。',
  main_card_8: '無憂用卡',
  main_card_9:
    '無卡號的實體卡可防止被盜用。您可隨時地更改交易密碼。只需一鍵即可凍結您的卡。',
  main_exchange_1: '實時國際匯兌。',
  main_exchange_2: '立即註冊',
  main_exchange_3: '更具競爭力的價格。',
  main_exchange_4:
    '我們的網上服務讓您享受實時國際匯兌匯率,與傳統銀行相比,為您節省8倍以上開支。',
  main_exchange_5: '即期合約。',
  main_exchange_6:
    '當您需要立即進行國際支付時,即期合約會提供實時批發匯率,而您的收款方會在當天或第二天收到相應金額。',
  main_exchange_7: '遠期合約。',
  main_exchange_8:
    '對於希望保護利潤率和管理外匯風險的企業,最多可以提前一年鎖定匯率。',
  main_contact_1: '聯系我們',
  main_contact_2: '您的名字:',
  main_contact_3: '請輸入您的名字',
  main_contact_4: '請輸入您的名字',
  main_contact_5: '您的姓氏：',
  main_contact_6: '請輸入您的姓氏',
  main_contact_7: '請輸入您的姓氏',
  main_contact_8: '您的公司名字：',
  main_contact_9: '請輸入您的公司名字',
  main_contact_10: '請輸入您的公司名字',
  main_contact_11: '您的郵箱地址：',
  main_contact_12: '請輸入您的郵箱地址',
  main_contact_13: '請輸入您的郵箱地址',
  main_contact_14: '具體問題描述：',
  main_contact_15: '請輸入具體問題描述',
  main_contact_16: '提交',
  main_contact_17: '歡迎聯系我們',
  main_contact_18: '了解更多',

  my_walletAddr_1: '錢包地址',
  my_walletAddr_2: '新增錢包地址',
  my_walletAddr_3: '數字貨幣類型',
  my_walletAddr_4: '地址',
  my_walletAddr_5: '操作',
  my_walletAddr_6: '已使用',
  my_walletAddr_7: '確認刪除錢包地址？',
  my_setPayPassword_1: '修改支付密碼',
  my_setPayPassword_2: '您的密碼長度至少應為8位數,並包含至少1個以下字符',
  my_setPayPassword_3: '大寫字母',
  my_setPayPassword_4: '小寫字母',
  my_setPayPassword_5: '數字',
  my_setPayPassword_6: '特殊字符',
  my_setPayPassword_7: '新支付密碼：',
  my_setPayPassword_8: '請輸入新支付密碼',
  my_setPayPassword_9: '重復支付密碼：',
  my_setPayPassword_10: '請再次輸入新支付密碼',
  my_setPayPassword_11: '取消',
  my_setPayPassword_12: '保存',
  my_setPayPassword_13: '請輸入密碼',
  my_setPayPassword_14: '密碼格式不正確',
  my_setPayPassword_15: '請再次輸入密碼',
  my_setPayPassword_16: '兩次輸入密碼不一致！',
  my_setPassword_1: '修改密碼',
  my_setPassword_2: '您的密碼長度至少應為八(8)位數,並包含至少一(1)個以下字符',
  my_setPassword_3: '大寫字母',
  my_setPassword_4: '小寫字母',
  my_setPassword_5: '數字',
  my_setPassword_6: '特殊字符',
  my_setPassword_7: '新密碼：',
  my_setPassword_8: '請輸入新密碼',
  my_setPassword_9: '重復新密碼：',
  my_setPassword_10: '請再次輸入新密碼',
  my_setPassword_11: '取消',
  my_setPassword_12: '保存',
  my_setPassword_13: '請輸入密碼',
  my_setPassword_14: '密碼格式不正確',
  my_setPassword_15: '請再次輸入密碼',
  my_setPassword_16: '兩次輸入密碼不一致！',
  my_setPassportCode_1: '修改手機號',
  my_setPassportCode_2: '護照號碼：',
  my_setPassportCode_3: '請輸入護照號碼',
  my_setPassportCode_4: '取消',
  my_setPassportCode_5: '保存',
  my_setMobile_1: '修改手機號',
  my_setMobile_2: '手機號：',
  my_setMobile_3: '請輸入新手機號',
  my_setMobile_4: '取消',
  my_setMobile_5: '保存',
  my_safety_1: '安全設置',
  my_safety_2: '登陸密碼',
  my_safety_3: '請輸入密碼',
  my_safety_4: '修改',
  my_safety_5: '支付密碼',
  my_safety_6: '請輸入密碼',
  my_safety_7: '修改',
  my_logBinding_1: '安全登陸綁定',
  my_logBinding_2: 'Google驗證',
  my_logBinding_3: '請輸入手機號',
  my_logBinding_4: '修改',
  my_logBinding_5: '郵箱驗證',
  my_datum_1: '個人資料',
  my_datum_2: '姓名',
  my_datum_3: '手機號',
  my_datum_4: '請輸入手機號',
  my_datum_5: '修改',
  my_datum_6: '簽發國家',
  my_datum_7: '護照號碼',
  my_datum_8: '請輸入護照號碼',
  my_datum_9: '修改',
  my_datum_10: '護照照片',
  my_addWalletAddr_1: '錢包地址管理',
  my_addWalletAddr_2: '幣種：',
  my_addWalletAddr_3: '請選擇幣種',
  my_addWalletAddr_4: '錢包地址：',
  my_addWalletAddr_5: '請輸入錢包地址',
  my_addWalletAddr_6: '取消',
  my_addWalletAddr_7: '保存',
  my_addWalletAddr_8: '請選擇幣種',
  my_addWalletAddr_9: '請輸入地址',
  my_record_1: '申請記錄',
  my_record_2: '申請時間',
  my_record_3: '計劃申請額度',
  my_record_4: '申請手續費',
  my_record_5: '狀態',
  my_record_6: '未處理',
  my_record_7: '已處理',
  my_record_8: '駁回',
  my_record_9: '確認',
  my_limit_1: '兌換額度',
  my_limit_2: '年度總額度',
  my_limit_3: '額度使用',
  my_limit_4: '剩余：',
  my_limit_5: '申請額度',
  my_limit_6: '額度不夠？點擊立即申請',
  my_limit_7: '申請記錄',
  my_limit_8: '貨幣兌換',
  my_limit_9: '已使用',
  my_nav_1: '個人信息',
  my_nav_2: '我的額度',
  my_nav_3: '錢包地址',
  my_nav_4: '銀行卡管理',
  my_nav_5: '邀請註冊',
  my_selectAddr_1: '收件地址管理',
  my_selectAddr_2: '默認',
  my_selectAddr_3: '新增收件地址',
  my_bankCardDetail_1: '銀行卡詳情',
  my_bankCardDetail_2: '姓名（中文）：',
  my_bankCardDetail_3: '姓名（英文）：',
  my_bankCardDetail_4: '卡號：',
  my_bankCardDetail_5: '銀行全稱（中文）：',
  my_bankCardDetail_6: '銀行全稱（英文）：',
  my_bankCardDetail_7: 'SWIFT代碼:',
  my_bankCardDetail_8: '銀行地址：',
  my_bankCardDetail_9: '聯系地址：',
  my_bankCardDetail_10: '聯系電話：',
  my_bankCardDetail_11: '郵編：',
  my_bankCardDetail_12: '關閉',
  my_bankCard_1: '銀行卡管理',
  my_bankCard_2: '新增',
  my_bankCard_3: '卡號',
  my_bankCard_4: '狀態：已申請',
  my_bankCard_5: '狀態：製作中',
  my_bankCard_6: '查看詳情',
  my_bankCard_7: '申請實體卡',
  my_bankCard_8: '取消申請',
  my_bankCard_9: '確認要刪除此銀行卡嗎',
  my_bankCard_10: '提示',
  my_bankCard_11: '確定',
  my_bankCard_12: '取消',
  my_bankCard_13: '確認要取消申請實體卡嗎?',
  my_bankCard_14: '提示',
  my_bankCard_15: '確定',
  my_bankCard_16: '取消',
  my_applyRealCard_1: '申請實體卡',
  my_applyRealCard_2: '姓名（中文）：',
  my_applyRealCard_3: '姓名（英文）：',
  my_applyRealCard_4: '卡號：',
  my_applyRealCard_5: '銀行全稱（中文）：',
  my_applyRealCard_6: '銀行全稱（英文）：',
  my_applyRealCard_7: '郵寄地址：',
  my_applyRealCard_8: '製作費：',
  my_applyRealCard_9: '郵費：',
  my_applyRealCard_10: '取消',
  my_applyRealCard_11: '立即申請',
  my_addBankCard_1: '銀行卡',
  my_addBankCard_2: '姓名（中文）：',
  my_addBankCard_3: '請輸入姓名',
  my_addBankCard_4: '姓名（英文）：',
  my_addBankCard_5: '請輸入姓名',
  my_addBankCard_6: '卡號：',
  my_addBankCard_7: '請輸入卡號',
  my_addBankCard_8: '銀行全稱（中文）：',
  my_addBankCard_9: '請輸入銀行全稱',
  my_addBankCard_10: '銀行全稱（英文）：',
  my_addBankCard_11: '請輸入銀行全稱',
  my_addBankCard_12: 'SWIFT代碼:',
  my_addBankCard_13: '請輸入SWIFT代碼',
  my_addBankCard_14: '銀行地址：',
  my_addBankCard_15: '請輸入銀行地址',
  my_addBankCard_16: '聯系地址：',
  my_addBankCard_17: '請輸入聯系地址',
  my_addBankCard_18: '聯系電話：',
  my_addBankCard_19: '請輸入聯系電話',
  my_addBankCard_20: '郵編：',
  my_addBankCard_21: '請輸入郵編',
  my_addBankCard_22: '取消',
  my_addBankCard_23: '保存',
  my_addBankCard_24: '請輸入卡號',
  my_addBankCard_25: '請輸入SWIFT代碼',
  my_addBankCard_26: '請輸入銀行地址',
  my_addBankCard_27: '請輸入聯系地址',
  my_addBankCard_28: '請輸入聯系電話',
  my_addBankCard_29: '請輸入郵編',
  my_addAddr_1: '新增收貨地址',
  my_addAddr_2: '收貨人姓名：',
  my_addAddr_3: '請輸入收貨人姓名',
  my_addAddr_4: '請輸入收貨人姓名',
  my_addAddr_5: '手機號：',
  my_addAddr_6: '請輸入手機號',
  my_addAddr_7: '請輸入手機號',
  my_addAddr_8: '請選擇國家',
  my_addAddr_9: '國家：',
  my_addAddr_10: '請選擇國家',
  my_addAddr_11: '請輸入省市縣信息',
  my_addAddr_12: '省市縣：',
  my_addAddr_13: '請輸入省市縣信息',
  my_addAddr_14: '請輸入街道、小區門牌等詳細地址',
  my_addAddr_15: '詳細地址：',
  my_addAddr_16: '請輸入街道、小區門牌等詳細地址',
  my_addAddr_17: '郵政編碼：',
  my_addAddr_18: '請輸入郵政編碼',
  my_addAddr_19: '設為默認地址',
  my_addAddr_20: '取消',
  my_addAddr_21: '保存',
  my_usdPayment_1: '立即支付',
  my_usdPayment_2: '錢包地址：',
  my_usdPayment_3: '收款碼：',
  my_usdPayment_4: '哈希值：',
  my_usdPayment_5: '請輸入付款哈希值信息',
  my_usdPayment_6: '請輸入付款哈希值信息',
  my_usdPayment_7: '必須使用平臺內登記地址轉賬,否則轉賬將會丟失。',
  my_usdPayment_8: '如未登記,請點擊',
  my_usdPayment_9: '地址登記',
  my_usdPayment_10: '取消',
  my_usdPayment_11: '已支付',
  my_conversion_1: '貨幣兌換',
  my_conversion_2: '選擇兌換方式：',
  my_conversion_3: '兌換為美金',
  my_conversion_4: '兌換為數字貨幣',
  my_conversion_5: '選擇幣種：',
  my_conversion_6: '請選擇您要使用的幣種',
  my_conversion_7: '請選擇您要使用的幣種',
  my_conversion_8: '當前幣種匯率',
  my_conversion_9: '美元',
  my_conversion_10: '選擇公鏈：',
  my_conversion_11: '請先選擇幣種',
  my_conversion_12: '收款美金賬戶：',
  my_conversion_13: '請選擇收款賬戶',
  my_conversion_14: '請選擇收款美金賬戶',
  my_conversion_15: '兌換數量：',
  my_conversion_16: '請輸入您要兌換的幣種數量',
  my_conversion_17: '請輸入您要兌換的幣種數量',
  my_conversion_18: '實際到賬金額：',
  my_conversion_19: '手續費金額：',
  my_conversion_20: '當前剩余兌換額度',
  my_conversion_21: '您當前已超額申請兌換,請點擊',
  my_conversion_22: '申請更多額度',
  my_conversion_23: '立即兌換',
  my_conversion_24: '已提交此次申請,申請通過將以郵件形式通知您！',
  my_conversion_25: '提示',
  my_conversion_26: '確定',
  my_conversion_27: '請先選擇您要使用的幣種',
  my_enhance_1: '兌換額度申請',
  my_enhance_2: '年度總額度：',
  my_enhance_3: '當前剩余額度：',
  my_enhance_4: '計劃申請額度：',
  my_enhance_5: '請輸入您要申請的額度',
  my_enhance_6: '請輸入您要申請的額度',
  my_enhance_7: '申請手續費：',
  my_enhance_8: '立即兌換',
  my_enhance_9: '已提交此次申請,申請通過將以郵件形式通知您！',
  my_enhance_10: '提示',
  my_enhance_11: '確定',
  my_currencyPayment_1: '匯款銀行：',
  my_currencyPayment_2: '匯款銀行',
  my_currencyPayment_3: '收款賬戶',
  my_currencyPayment_4: '收款賬戶聯系地址：',
  my_currencyPayment_5: '收款賬戶聯系地址',
  my_currencyPayment_6: '開戶行名稱：',
  my_currencyPayment_7: '開戶行名稱',
  my_currencyPayment_8: 'SWIFT代碼:',
  my_currencyPayment_9: 'SWIFT代碼',
  my_currencyPayment_10: '開戶行地址：',
  my_currencyPayment_11: '開戶行地址',
  my_currencyPayment_12: '註意：轉賬請填寫附言,附言內容為訂單號。',
  my_currencyPayment_13: '取消',
  my_currencyPayment_14: '已支付',
  index_team_1: '我們的管理團隊',
  index_team_2: '聯合創始人、董事長兼首席執行官',
  index_team2_2: '首席運營官官',
  index_team3_2: '首席戰畧官兼全球政策負責人',
  index_team_content:"擁有金融服務20多年的經驗。 專注於個人財富管理，提供個人理財規劃服務和選定客戶的資產配寘，憑藉處理散戶、企業和機構投資者的經驗，期待為所有級別的投資者打造一個全新平臺。",
  index_team2_content:"負責監督業務的業務發展。 憑藉他在商業諮詢、行銷、融資和業務發展方面的多年經驗。 他擁有經過驗證的為投資和企業籌集數千萬資金的記錄。",
  index_team3_content:"區塊鏈和元宇宙投資人，對互聯網和科技領域的多家上市公司擁有投資經驗，DEFI、NFT、Gamefi等多個賽道的區塊鏈領域均有所涉獵與佈局。",
  index_cooperation_1: '與我們一起創造不凡',
  index_cooperation_2: '18種貨幣兌換方式',
  index_cooperation_3: '5000+服務客戶',
  index_cooperation_4: '橫跨30多個國家',
  index_brief_1: '企業簡介',
  my_setPassportCode_6: '護照號碼格式不正確',
  my_bankCard_17: '狀態：已發放',
  '收款钱包地址：': '收款錢包地址：',
  请选择收款钱包地址: '請選擇收款錢包地址',
  '实际到账数量：': '實際到賬數量：',
  请立即支付: '請立即支付',
  '开户费用 ,便于为您开户 。': '開戶費用，便於為您開戶。',
  立即支付: '立即支付',
  '币种：': '幣種：',
  '公链：': '公鏈：',
  请选择公链: '請選擇公鏈',
  '钱包地址：': '錢包地址：',
  '收款码：': '收款碼：',
  '哈希值：': '雜湊值：',
  取消: '取消',
  已支付: '已支付',
  请选择币种: '請選擇幣種',
  立即提交: '立即提交',
  main_account_9: '支付直通車',
  main_account_10:
    '以您的香港多幣種帳戶收取匯款，並將款項匯給100多個國家的收款人。',
  立即开始兑换: '立即開始兌換',
  '咨询类型：': '諮詢類型',
  请选择咨询类型: '請選擇諮詢類型',
  '上传附件：': '上傳附件：',

  ...zhTWLocale,
   company_profiles_content:'Meta Bank是一家全球金融科技公司，處於數位貨幣創新和開放金融基礎設施的中心。 在Meta，我們的使命是全民普惠金融，架起傳統金融系統和世界領先的公共區塊鏈的橋樑，為全球企業和投資者開啟增長之路，為實體經濟覈心社區的社會和經濟效益做出貢獻，努力消除金融准入的障礙並促進經濟流動性。 Meta致力於為所有人新增財務可用性、選擇和機會。'
}
export default zhTW
