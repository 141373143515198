import Vue from 'vue'
import ElementUI from 'element-ui'
import 'vant/lib/index.css'
import 'element-ui/lib/theme-chalk/index.css'
import i18n from './i18n/i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import service from '@/utils/request'

import '@/assets/css/comm.scss'

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.use(ElementUI)
Vue.prototype.$axios = service
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  data: {
    eventHub: new Vue(),
  },
  render: (h) => h(App),
}).$mount('#app')
