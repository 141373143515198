import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
import { loginout } from '@/utils/tool'
import comm from '@/utils/comm'

// let port = location.protocol
// let port = 'https://'
// let ip = comm.cesuUrl
let requestUrl = comm.cesuUrl

let isMagg = true

const service = axios.create({
  baseURL: `${requestUrl}`,
  timeout: 30000,
})

service.interceptors.request.use(
  (config) => {
    const role = localStorage.getItem('sm_token')
    const lang = localStorage.lang == undefined ? 'cn' : localStorage.lang
    if (role) {
      config.headers['token'] = role
    }
    config.headers['lang'] = lang
    return config
  },
  (error) => {
    console.log(error)
    Message.error(error)
    return Promise.reject()
  }
)

service.interceptors.response.use(
  (response) => {
    // console.log(response)
    const status = Number(response.data.code)
    const message = response.data.msg || '未知错误'
    // 登录过期
    if (status === 401) {
      if (isMagg) {
        Message.error(message)
        isMagg = false
        setTimeout(() => {
          isMagg = true
        }, 1000)
      }
      setTimeout(() => {
        loginout()
      }, 1000)
      return Promise.reject(new Error(message))
    }
    // -66 弹框信息
    if (status !== 1 && status !== -66) {
      if (isMagg) {
        Message.error(message)
        isMagg = false
        setTimeout(() => {
          isMagg = true
        }, 1000)
      }
      Promise.reject(new Error(message))
      return response.data
    }
    return response.data
  },
  (error) => {
    if (error.response.status === 401) {
      if (isMagg) {
        Message.error('未授权，请重新登录(401)')
        isMagg = false
        setTimeout(() => {
          isMagg = true
        }, 1000)
      }
      setTimeout(() => {
        localStorage.removeItem('sm_token')
        localStorage.removeItem('sm_email')
        localStorage.removeItem('sm_type')
        localStorage.removeItem('sm_userinfo')
        router.push({ name: 'Login' })
      }, 1000)
      return
    }
    if (isMagg) {
      Message.error(error)
      isMagg = false
      setTimeout(() => {
        isMagg = true
      }, 1000)
    }
    Promise.reject(error)
    return error
  }
)

export default service
