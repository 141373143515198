import enLocale from 'element-ui/lib/locale/lang/en' //引入element语言包
const en = {
  header_1: 'Home',
  header_2: 'Bank card',
  header_3: 'About the company',
  header_4: 'Common problem',
  header_5: 'Login',
  header_6: 'Register',
  header_7: 'Exit',
  footer_1: 'my company name',
  footer_2: "Advice: Be sure to repay the loan, don't pay any brokerage fees",
  footer_3: 'Function',
  footer_4: 'Account',
  footer_5: 'Money transfer',
  footer_6: 'Currency exchange',
  footer_7: 'Help Center',
  footer_8: 'Contact us',
  footer_9: 'About us',
  footer_10: 'Privacy Policy',
  footer_11: 'Resource',
  footer_12: 'Company',
  footer_13:
    'Advice: Be sure to repay the loan and do not pay any brokerage fees',
  index_banner_1: 'One Account, Universal Worldwide',
  index_banner_2:
    'One-stop simplified cross-border payments: multi-currency accounts, business visa cards, international remittance and exchange',
  index_banner_3: 'Start immediately',
  index_banner_4:
    'Very simple user interface, fast account opening, low cost, excellent service, I highly recommend',
  index_banner_5:
    'Fast, efficient and professional customer service, highly recommended',
  index_banner_6:
    'Digital financial services years ahead of the banking industry',
  index_business_1: 'Solutions to meet various business requirements',
  index_business_2: 'Account',
  index_business_3: 'Everywhere',
  index_business_4:
    'Need a bank account? Just select a region in your global account and we will open a virtual account number exclusively for you at the local partner bank. All funds you receive can be deposited directly into your multi-currency wallet.',
  index_business_5: 'Apply immediately',
  index_bank_1: 'Money transfer',
  index_bank_2:
    'Send and receive payments worldwide with ease. Receive and pay in multiple currencies in multiple countries across the country, showing your local advantage. Account management is more flexible, fast and preferential.',
  index_bank_3: 'Metabank Card',
  index_bank_4: 'Anytime, anywhere, free consumption',
  index_bank_5:
    'With Metabank, you can make and offline payments with global account funds. Easily pay any fees with your card at the Visa-enabled merchant network.',
  index_bank_6: 'Apply immediately',
  index_conversion_1: 'Currency exchange',
  index_conversion_2: 'Easy and transparent, easy to get',
  index_conversion_3:
    "Just like on XE.com or Reuters, you get real mid-market rates. Exchange currencies for only the best rates, so you don't need to spend time comparing.",
  index_conversion_4: 'Apply immediately',
  index_issue_1: 'Frequently Asked Questions',
  index_issue_2: 'List of Questions',
  index_issue_3: 'More',
  index_con_1: 'Contact us',
  faq_1: 'Frequently Asked Questions',
  faq_2: 'Know more',
  faq_3: 'Common problem',
  login_1: 'Please input the email address',
  login_2: 'Please input the correct email address',
  login_3: 'Email',
  login_4: 'Please enter password',
  login_5: 'Password',
  login_6: "Don't have an account? Register an account now",
  login_7: 'Forgot password？',
  login_8: 'Log in',
  login_9: 'I have read and agree',
  login_10: '《Privacy Policy》',
  login_11: 'need help? Contact us now',

  reg_email_1: "Let's start",
  reg_email_2:
    'The process of applying for a free business account will take 10 to 15 minutes. Once you start, you can save your progress and come back anytime to continue your application.',
  reg_email_3:
    'The application process should be completed by an authorized representative of your company. You will need to provide business registration documents, personal identification documents and proof of personal address for all company directors and major shareholders.',
  reg_email_4:
    'The Financial Regulations stipulate that Metabank must comply with Know Your Customer (KYC) requirements, Anti-Money Laundering (AML) and Counter-Terrorism Financing (CFT) regulations.',
  reg_email_5:
    'Therefore, it is important to provide complete, clear and accurate business information, including financial transactions. Incomplete or inconsistent information may result in a delay in your business account application, or even a denial. We look forward to serving you.',
  reg_email_6: 'E-mail:',
  reg_email_7: 'Please input the email address',
  reg_email_8: 'Please input the correct email address',
  reg_email_9: 'Please input the email address',
  reg_email_10: 'Submit now',
  reg_email_11: 'Already have an account, log in now',
  reg_email_12: 'Verification link sent to email',
  reg_identity_1: 'Please select your registration status',
  reg_identity_2: 'Individual users outside mainland China',
  reg_identity_3: 'Institutional User',
  reg_identity_4: 'Mainland individual users',
  reg_identity_5:
    'Note: Once the registration is successful, the identity will not be changed',
  reg_identity_6: 'Continue',
  reg_identity_7: 'Return',
  reg_mainLandUser_1: 'Personal details',
  reg_mainLandUser_2: 'your name:',
  reg_mainLandUser_3: 'Please enter your name',
  reg_mainLandUser_4: 'Please enter your name',
  reg_mainLandUser_5: 'Your last name:',
  reg_mainLandUser_6: 'Please enter your last name',
  reg_mainLandUser_7: 'Please enter your last name',
  reg_mainLandUser_8: 'phone number:',
  reg_mainLandUser_9: 'please enter your phone number',
  reg_mainLandUser_10: 'please enter your phone number',
  reg_mainLandUser_11: 'Continue',
  reg_mainLandUser_12: 'Return',
  reg_identityVerification_1: 'Authentication',
  reg_identityVerification_2: 'Please scan the QR code with your mobile phone',
  reg_identityVerification_3: 'Tutorial',
  reg_identityVerification_4:
    'If the phone camera is pointed at the QR code and it does not work, you can download a QR code scanner from Google Play or the App Store',
  reg_identityVerification_5: 'How to scan QR code',
  reg_identityVerification_6: 'Continue',
  reg_identityVerification_7: 'Return',
  reg_guidance_1: 'Mobile session linked to your computer',
  reg_guidance_2: 'Continue with the verifiication',
  reg_guidance_3: 'Double—check',
  reg_guidance_4:
    'This link  was sent by  you - seek advice if  youthink  this could be a scam',
  reg_guidance_5: 'Your desktop window remains open',
  reg_guidance_6: 'Continue',
  reg_uploading_1: 'Passport information upload',
  reg_uploading_2: 'Issuing country:',
  reg_uploading_3: 'Please select the issuing country',
  reg_uploading_4: 'Please select the issuing country',
  reg_uploading_5: 'Passport number:',
  reg_uploading_6: 'Please enter your passport number',
  reg_uploading_7: 'Please enter your passport number',
  reg_uploading_8: 'Passport cover photo:',
  reg_uploading_9: 'Please select a passport cover photo',
  reg_uploading_10: 'Passport front page photo:',
  reg_uploading_11: 'Please select the passport page photo',
  reg_uploading_12: 'Passport first page photo:',
  reg_uploading_13: 'Please select a handheld homepage photo',
  reg_uploading_14: 'Submit',
  reg_uploading_15: 'Please select the issuing country',
  reg_affirm_1:
    'Make sure the information on the card is legible, not blurred, and non-reflective',
  reg_affirm_2: 're-upload',
  reg_affirm_3: 'Confirm',
  reg_bankInfo_1: 'Bank information submission',
  reg_bankInfo_2: 'Proof of address (must be issued within 3 months)',
  reg_bankInfo_3: 'illustrate',
  reg_bankInfo_4:
    'This information is used for the subsequent production of bank cards, and the valid address proof is the address water and electricity bill within three months, or the property ownership certificate.',
  reg_bankInfo_5: 'Upload files',
  reg_bankInfo_6: 'Extension support',
  reg_bankInfo_7: 'Continue',
  reg_bankInfo_8: 'One-click synchronous submission',
  reg_bankInfo_9: 'Return',
  reg_limit_1: 'Initial exchange amount',
  reg_limit_2: 'Your current identity is',
  reg_limit_3: 'Your initial total annual redemption limit is',
  reg_limit_4:
    'To apply for additional quota, please click "Additional Application"',
  reg_limit_5: 'Continue',
  reg_limit_6: 'Return',

  reg_setPassword_1: 'Set password',
  reg_setPassword_2:
    'Your password should be at least eight (8) digits long and contain at least one (1) character below',
  reg_setPassword_3: 'Uppercase letter',
  reg_setPassword_4: 'Lower case letters',
  reg_setPassword_5: 'Number',
  reg_setPassword_6: 'Special characters',
  reg_setPassword_7: 'Password',
  reg_setPassword_8: 'Please enter password',
  reg_setPassword_9: 'Confirm Password',
  reg_setPassword_10: 'Please enter the password again',
  reg_setPassword_11: 'Continue',
  reg_setPassword_12: 'Return',
  reg_setPassword_13: 'Already have an account, log in now',
  reg_setPassword_14: 'Please enter password',
  reg_setPassword_15: 'Incorrect password format',
  reg_setPassword_16: 'Please enter the password again',
  reg_setPassword_17: 'The passwords entered twice do not match',
  reg_setPayPass_1: 'Set up payment password',
  reg_setPayPass_2:
    'Your password should be at least eight (8) digits long and contain at least one (1) character below',
  reg_setPayPass_3: 'Uppercase letter',
  reg_setPayPass_4: 'Lower case letters',
  reg_setPayPass_5: 'Number',
  reg_setPayPass_6: 'Special characters',
  reg_setPayPass_7: 'Payment password',
  reg_setPayPass_8: 'Please enter password',
  reg_setPayPass_9: 'Confirm Password',
  reg_setPayPass_10: 'Please enter the password again',
  reg_setPayPass_11: 'Continue',
  reg_setPayPass_12: 'Return',
  reg_applySuccess_1: 'Successful application',
  reg_applySuccess_2:
    'Please pay the 150U account opening fee immediately so that we can open an account for you.',
  reg_applySuccess_3: 'Pay immediately',
  reg_applySuccess_4: 'Please wait for staff review',
  reg_applySuccess_5: 'Pay immediately',
  reg_applySuccess_6: 'Wallet address:',
  reg_applySuccess_7: 'Receipt code:',
  reg_applySuccess_8: 'Hash value:',
  reg_applySuccess_9: 'Please enter payment hash information',
  reg_applySuccess_10: 'Please enter payment hash information',
  reg_applySuccess_11:
    'Please use your own wallet to transfer money, this payment wallet will be automatically saved as your usual wallet address.',
  reg_applySuccess_12: 'Cancel',
  reg_applySuccess_13: 'Paid',
  reg_findPassword_1: 'Retrieve password',
  reg_findPassword_2:
    'Your password should be at least eight (8) digits long and contain at least one (1) character below',
  reg_findPassword_3: 'Uppercase letter',
  reg_findPassword_4: 'Lower case letters',
  reg_findPassword_5: 'Number',
  reg_findPassword_6: 'Special characters',
  reg_findPassword_7: 'Password',
  reg_findPassword_8: 'Please enter password',
  reg_findPassword_9: 'Confirm Password',
  reg_findPassword_10: 'Please enter the password again',
  reg_findPassword_11: 'Reset now',
  reg_forgetPassword_1: 'Forgot password',
  reg_forgetPassword_2:
    'We will email you with instructions to reset your password',
  reg_forgetPassword_3: 'E-mail:',
  reg_forgetPassword_4: 'Please input the email address',
  reg_forgetPassword_5: 'Please input the correct email address',
  reg_forgetPassword_6: 'Please input the email address',
  reg_forgetPassword_7: 'Submit',
  reg_forgetPassword_8: 'Return',
  reg_forgetPassword_9: 'Verification link has been emailed',
  reg_organizationUser_1: 'Institution Details',
  reg_organizationUser_2: 'Institution name:',
  reg_organizationUser_3: 'Please enter institution name',
  reg_organizationUser_4: 'Please enter institution name',
  reg_organizationUser_5: 'Legal person name:',
  reg_organizationUser_6: 'Please enter the Legal person name',
  reg_organizationUser_7: 'Please enter the Legal person name',
  reg_organizationUser_8: 'Legal person last name:',
  reg_organizationUser_9: 'Please enter the Legal person last name',
  reg_organizationUser_10: 'Please enter the Legal person last name',
  reg_organizationUser_11: 'Contact information:',
  reg_organizationUser_12: 'Please enter your phone number',
  reg_organizationUser_13: 'Please enter your phone number',
  reg_organizationUser_14: 'Institution Registration Page',
  reg_organizationUser_15: 'Illustrate',
  reg_organizationUser_16:
    'Utility bills or mobile/bank card/bank statement within the last 3 months.',
  reg_organizationUser_17: 'Upload files',
  reg_organizationUser_18: 'Extension support',
  reg_organizationUser_19: 'Continue',
  reg_organizationUser_20: 'Return',
  reg_overseasUser_1: 'Overseas registration details',
  reg_overseasUser_2: 'Your name:',
  reg_overseasUser_3: 'Please enter your name',
  reg_overseasUser_4: 'Please enter your name',
  reg_overseasUser_5: 'Your last name:',
  reg_overseasUser_6: 'Please enter your last name',
  reg_overseasUser_7: 'Please enter your last name',
  reg_overseasUser_8: 'Phone number:',
  reg_overseasUser_9: 'Please enter your phone number',
  reg_overseasUser_10: 'Please choose',
  reg_overseasUser_11: 'Please enter your phone number',
  reg_overseasUser_12: 'Proof of address (must be issued within 3 months)',
  reg_overseasUser_13: 'Illustrate',
  reg_overseasUser_14:
    'Utility bills or mobile/bank card/bank statement within the last 3 months.',
  reg_overseasUser_15: 'Upload files',
  reg_overseasUser_16: 'Extension support',
  reg_overseasUser_17: 'Continue',
  reg_overseasUser_18: 'Return',

  main_account_1: 'Cross-border Merchant Account',
  main_account_2: 'Register',
  main_account_3:
    'An easier way to receive/payment and exchange foreign currency.',
  main_account_4:
    'Send and receive payments worldwide with ease. Send and receive payments in multiple currencies in multiple countries around the world, always with a local advantage. Plus, you can enjoy zero foreign exchange losses on true cross-border transactions and the use of a Visa card for everything you need to expand your global business.',
  main_account_5:
    'Foreign currency accounts allow you to go international at any time.',
  main_account_6:
    'With national banks in Hong Kong, Canada, Europe, Indonesia, Japan, India, UK and US, we make more.',
  main_account_7: 'New-generation financial platform.',
  main_account_8:
    "With 24/7 access via web and app, powerful support from payment and credit engines, and transparent and affordable fees, it's your best choice for receiving, exchanging foreign currency and transferring money.",
  main_payment_1: 'Enjoy local treatment wherever you do business.',
  main_payment_2: 'Register',
  main_payment_3: 'Payment methods in the digital economy.',
  main_payment_4:
    'Businesses can now easily buy and sell globally, so we must make it equally easy for businesses to send and receive payments globally. Pay with a global account, enabling you to open a new foreign currency account in the country of your choice and giving you a dedicated bank account.',
  main_payment_5: 'More affordable, faster and safer.',
  main_payment_6:
    'We support up to 14 receiving currencies and 18 payment currencies. Seamless cross-border payments with no correspondent bank fees. Plus, you get a real middle price.',
  main_payment_7: 'Real "covering the world".',
  main_payment_8:
    'Get paid in USD, GBP, EUR and other international currencies through virtual accounts covering more than 30 countries. Connect your account with e-commerce platforms like Amazon and Shopify, or with third-party payments like Stripe and PayPal.',
  main_payment_9: 'Pay through train',
  main_payment_10:
    'Receive remittances from your Hong Kong multi-currency account and send money to recipients in over 100 countries.',
  main_card_1: 'Bring you the most flexible financial experience.',
  main_card_2: 'Sign up now',
  main_card_3: 'Your peace of mind.',
  main_card_4: 'Instant approval',
  main_card_5:
    'In just 6 clicks, Global Account customers can get a new virtual card! Start spending online today.',
  main_card_6: 'High security',
  main_card_7:
    'Sensitive information for payment cards will be securely stored in the Currenxie app. Only you have access to this information.',
  main_card_8: 'worry-free card',
  main_card_9:
    'A physical card without a card number prevents theft. You can change the transaction password at any time. Freeze your card with just one click.',
  main_exchange_1: 'Real-time international exchange.',
  main_exchange_2: 'Sign up now',
  main_exchange_3: 'More competitive prices.',
  main_exchange_4:
    'Our online service allows you to enjoy real-time international exchange rates, saving you more than 8 times compared to traditional banks.',
  main_exchange_5: 'Spot contract.',
  main_exchange_6:
    'When you need to make an immediate international payment, spot contracts offer real-time wholesale exchange rates, and your recipient receives the amount on the same day or the next day.',
  main_exchange_7: 'Forward contract.',
  main_exchange_8:
    'For businesses looking to protect profit margins and manage foreign exchange risk, exchange rates can be locked in up to one year in advance.',
  main_contact_1: 'Contact us',
  main_contact_2: 'your name:',
  main_contact_3: 'Please enter your name',
  main_contact_4: 'Please enter your name',
  main_contact_5: 'Your last name:',
  main_contact_6: 'Please enter your last name',
  main_contact_7: 'Please enter your last name',
  main_contact_8: 'Your company name:',
  main_contact_9: 'Please enter your company name',
  main_contact_10: 'Please enter your company name',
  main_contact_11: 'Your email address:',
  main_contact_12: 'Please enter your e-mail address',
  main_contact_13: 'Please enter your e-mail address',
  main_contact_14: 'Specific problem description:',
  main_contact_15: 'Please enter a specific problem description',
  main_contact_16: 'Submit',
  main_contact_17: 'Welcome to contact us',
  main_contact_18: 'Know more',

  my_walletAddr_1: 'Wllet address',
  my_walletAddr_2: 'Add wallet address',
  my_walletAddr_3: 'Digital currency type',
  my_walletAddr_4: 'Address',
  my_walletAddr_5: 'Operate',
  my_walletAddr_6: 'Used',
  my_walletAddr_7: 'Confirm to delete wallet address?',
  my_setPayPassword_1: 'Change payment password',
  my_setPayPassword_2:
    'Your password should be at least 8 attacks and contain less than 1 character',
  my_setPayPassword_3: 'Uppercase letter',
  my_setPayPassword_4: 'Lower case letters',
  my_setPayPassword_5: 'Number',
  my_setPayPassword_6: 'Special characters',
  my_setPayPassword_7: 'New payment password:',
  my_setPayPassword_8: 'Please enter new payment password',
  my_setPayPassword_9: 'Repeat payment password:',
  my_setPayPassword_10: 'Please enter the new payment password again',
  my_setPayPassword_11: 'Cancel',
  my_setPayPassword_12: 'Save',
  my_setPayPassword_13: 'Please enter password',
  my_setPayPassword_14: 'Incorrect password format',
  my_setPayPassword_15: 'Please enter the password again',
  my_setPayPassword_16: 'The passwords entered twice do not match!',
  my_setPassword_1: 'Change password',
  my_setPassword_2:
    'Your password should be at least 8 attacks and contain less than 1 character',
  my_setPassword_3: 'Uppercase letter',
  my_setPassword_4: 'Lower case letters',
  my_setPassword_5: 'Number',
  my_setPassword_6: 'Special characters',
  my_setPassword_7: 'New password:',
  my_setPassword_8: 'Please enter a new password',
  my_setPassword_9: 'Repeat new password:',
  my_setPassword_10: 'Please enter new password again',
  my_setPassword_11: 'Cancel',
  my_setPassword_12: 'Save',
  my_setPassword_13: 'Please enter password',
  my_setPassword_14: 'Incorrect password format',
  my_setPassword_15: 'Please enter the password again',
  my_setPassword_16: 'The passwords entered twice do not match!',
  my_setPassportCode_1: 'Modify phone number',
  my_setPassportCode_2: 'Passport number:',
  my_setPassportCode_3: 'Please enter your passport number',
  my_setPassportCode_4: 'Cancel',
  my_setPassportCode_5: 'Save',
  my_setMobile_1: 'Modify phone number',
  my_setMobile_2: 'Phone number:',
  my_setMobile_3: 'Please enter a new phone number',
  my_setMobile_4: 'Cancel',
  my_setMobile_5: 'Save',
  my_safety_1: 'Security Settings',
  my_safety_2: 'Login password',
  my_safety_3: 'Please enter password',
  my_safety_4: 'Modify',
  my_safety_5: 'Payment password',
  my_safety_6: 'Please enter password',
  my_safety_7: 'Modify',
  my_logBinding_1: 'Secure login binding',
  my_logBinding_2: 'Google verification',
  my_logBinding_3: 'Please enter phone number',
  my_logBinding_4: 'Modify',
  my_logBinding_5: 'E-mail verification',
  my_datum_1: 'Personal information',
  my_datum_2: 'Name',
  my_datum_3: 'Phone number',
  my_datum_4: 'Please enter phone number',
  my_datum_5: 'Modify',
  my_datum_6: 'Issuing country',
  my_datum_7: 'Passport number',
  my_datum_8: 'Please enter your passport number',
  my_datum_9: 'Modify',
  my_datum_10: 'Passport photo',
  my_addWalletAddr_1: 'Wallet address management',
  my_addWalletAddr_2: 'Currency:',
  my_addWalletAddr_3: 'Please select a currency',
  my_addWalletAddr_4: 'Wallet address:',
  my_addWalletAddr_5: 'Please enter wallet address',
  my_addWalletAddr_6: 'Cancel',
  my_addWalletAddr_7: 'Save',
  my_addWalletAddr_8: 'Please select a currency',
  my_addWalletAddr_9: 'Please enter address',
  my_record_1: 'Application record',
  my_record_2: 'Application time',
  my_record_3: 'Program application quota',
  my_record_4: 'Application fee',
  my_record_5: 'State',
  my_record_6: 'Not processed',
  my_record_7: 'Processed',
  my_record_8: 'Turn down',
  my_record_9: 'Confirm',
  my_limit_1: 'Exchange amount',
  my_limit_2: 'Total annual quota',
  my_limit_3: 'Quota use',
  my_limit_4: 'Remaining:',
  my_limit_5: 'Application quota',
  my_limit_6: 'Not enough quota? Click to apply now',
  my_limit_7: 'Application record',
  my_limit_8: 'Currency exchange',
  my_limit_9: 'Used',
  my_nav_1: 'Personal information',
  my_nav_2: 'my quota',
  my_nav_3: 'wallet address',
  my_nav_4: 'Bank card management',
  my_nav_5: 'Invitation to register',
  my_selectAddr_1: 'Receiving address management',
  my_selectAddr_2: 'Default',
  my_selectAddr_3: 'Add delivery address',
  my_bankCardDetail_1: 'bank card details',
  my_bankCardDetail_2: 'Name (Chinese):',
  my_bankCardDetail_3: 'Name (English):',
  my_bankCardDetail_4: 'Card number:',
  my_bankCardDetail_5: 'Full name of the bank (Chinese):',
  my_bankCardDetail_6: 'Full name of the bank (in English):',
  my_bankCardDetail_7: 'SWIFT CODE:',
  my_bankCardDetail_8: 'Bank address:',
  my_bankCardDetail_9: 'Contact address:',
  my_bankCardDetail_10: 'Contact number:',
  my_bankCardDetail_11: 'Post code:',
  my_bankCardDetail_12: 'Close',
  my_bankCard_1: 'Bank card management',
  my_bankCard_2: 'Add',
  my_bankCard_3: 'Card number',
  my_bankCard_4: 'Status: Applied',
  my_bankCard_5: 'Status: in production',
  my_bankCard_6: 'Details',
  my_bankCard_7: 'Apply for a physical card',
  my_bankCard_8: 'Cancel',
  my_bankCard_9: 'Are you sure you want to delete this card',
  my_bankCard_10: 'Notice',
  my_bankCard_11: 'Confirm',
  my_bankCard_12: 'Cancel',
  my_bankCard_13:
    'Are you sure you want to cancel the application for a physical card?',
  my_bankCard_14: 'Notice',
  my_bankCard_15: 'Confirm',
  my_bankCard_16: 'Cancel',
  my_applyRealCard_1: 'Apply for a physical card',
  my_applyRealCard_2: 'Name (Chinese):',
  my_applyRealCard_3: 'Name (English):',
  my_applyRealCard_4: 'Card number:',
  my_applyRealCard_5: 'Full name of the bank (Chinese):',
  my_applyRealCard_6: 'Full name of the bank (in English):',
  my_applyRealCard_7: 'Mailing address:',
  my_applyRealCard_8: 'Production costs:',
  my_applyRealCard_9: 'Postage:',
  my_applyRealCard_10: 'Cancel',
  my_applyRealCard_11: 'Apply ',
  my_addBankCard_1: 'bank card',
  my_addBankCard_2: 'Name (Chinese):',
  my_addBankCard_3: 'Please type in your name',
  my_addBankCard_4: 'Name (English):',
  my_addBankCard_5: 'Please type in your name',
  my_addBankCard_6: 'Card number:',
  my_addBankCard_7: 'Please enter the card number',
  my_addBankCard_8: 'Full name of the bank (Chinese):',
  my_addBankCard_9: 'Please enter full bank name',
  my_addBankCard_10: 'Full name of the bank (in English):',
  my_addBankCard_11: 'Please enter full bank name',
  my_addBankCard_12: 'SWIFT code:',
  my_addBankCard_13: 'Please enter SWIFT code',
  my_addBankCard_14: 'Bank address:',
  my_addBankCard_15: 'Please enter bank address',
  my_addBankCard_16: 'Contact address:',
  my_addBankCard_17: 'Please enter a contact address',
  my_addBankCard_18: 'Phone number:',
  my_addBankCard_19: 'Please type your phone number',
  my_addBankCard_20: 'Post code:',
  my_addBankCard_21: 'Please enter zip code',
  my_addBankCard_22: 'Cancel',
  my_addBankCard_23: 'Save',
  my_addBankCard_24: 'Please enter the card number',
  my_addBankCard_25: 'Please enter SWIFT code',
  my_addBankCard_26: 'Please enter bank address',
  my_addBankCard_27: 'Please enter a contact address',
  my_addBankCard_28: 'Please type your phone number',
  my_addBankCard_29: 'Please enter zip code',
  my_addAddr_1: 'New shipping address',
  my_addAddr_2: 'Consignee name:',
  my_addAddr_3: 'Please enter consignee name',
  my_addAddr_4: 'Please enter consignee name',
  my_addAddr_5: 'Phone number:',
  my_addAddr_6: 'Please enter phone number',
  my_addAddr_7: 'Please enter phone number',
  my_addAddr_8: 'Please select a country',
  my_addAddr_9: 'Country:',
  my_addAddr_10: 'Please select a country',
  my_addAddr_11: 'Please enter the province, city and county information',
  my_addAddr_12: 'City:',
  my_addAddr_13: 'Please enter the province, city and county information',
  my_addAddr_14:
    'Please enter the detailed address such as street, community house number, etc.',
  my_addAddr_15: 'Address:',
  my_addAddr_16:
    'Please enter the detailed address such as street, community house number, etc.',
  my_addAddr_17: 'Postal Code:',
  my_addAddr_18: 'Set the default address',
  my_addAddr_19: 'Set as the default address',
  my_addAddr_20: 'Cancel',
  my_addAddr_21: 'Save',
  my_usdPayment_1: 'Payment',
  my_usdPayment_2: 'Wallet address:',
  my_usdPayment_3: 'Receipt code:',
  my_usdPayment_4: 'Hash value:',
  my_usdPayment_5: 'Please enter payment hash information',
  my_usdPayment_6: 'Please enter payment hash information',
  my_usdPayment_7:
    'You must use the registered address in the platform to transfer money, otherwise the transfer will be lost.',
  my_usdPayment_8: 'If not registered, please click',
  my_usdPayment_9: 'Address registration',
  my_usdPayment_10: 'Cancel',
  my_usdPayment_11: 'Paid',
  my_conversion_1: 'Currency exchange',
  my_conversion_2: 'Choose a redemption method:',
  my_conversion_3: 'Convert to US dollars',
  my_conversion_4: 'Convert to digital currency',
  my_conversion_5: 'Select currency:',
  my_conversion_6: 'Please select the currency you want to use',
  my_conversion_7: 'Please select the currency you want to use',
  my_conversion_8: 'Current currency exchange rate',
  my_conversion_9: 'Dollar',
  my_conversion_10: 'Choose a public chain:',
  my_conversion_11: 'Please select a currency first',
  my_conversion_12: 'Receiving USD account:',
  my_conversion_13: 'Please select a receiving account',
  my_conversion_14: 'Please select an account in USD',
  my_conversion_15: 'Exchange amount:',
  my_conversion_16: 'Please enter the amount of currency you want to exchange',
  my_conversion_17: 'Please enter the amount of currency you want to exchange',
  my_conversion_18: 'Actual amount received:',
  my_conversion_19: 'Amount of handling fee:',
  my_conversion_20: 'Current remaining redemption amount',
  my_conversion_21:
    'Your current redemption limit has been exceeded, please click here to request a limit',
  my_conversion_22: 'Apply for more quota',
  my_conversion_23: 'Exchange',
  my_conversion_24:
    'The application has been submitted, and you will be notified by email if the application is approved!',
  my_conversion_25: 'Notice',
  my_conversion_26: 'Confirm',
  my_conversion_27: 'Please select the currency you want to use first',
  my_enhance_1: 'Exchange quota application',
  my_enhance_2: 'Annual total amount:',
  my_enhance_3: 'Current remaining amount:',
  my_enhance_4: 'Program application quota:',
  my_enhance_5: 'Please enter the amount you want to apply',
  my_enhance_6: 'Please enter the amount you want to apply',
  my_enhance_7: 'Application fee:',
  my_enhance_8: 'Exchange',
  my_enhance_9:
    'The application has been submitted, and you will be notified by email if the application is approved!',
  my_enhance_10: 'Notice',
  my_enhance_11: 'Confirm',
  my_currencyPayment_1: 'Remittance Bank:',
  my_currencyPayment_2: 'Remittance Bank',
  my_currencyPayment_3: 'Accounts receivable',
  my_currencyPayment_4: 'Payee account contact address:',
  my_currencyPayment_5: 'Payee account contact address',
  my_currencyPayment_6: 'Bank name:',
  my_currencyPayment_7: 'Bank name:',
  my_currencyPayment_8: 'SWIFT CODE:',
  my_currencyPayment_9: 'SWIFT CODE',
  my_currencyPayment_10: 'Account opening address:',
  my_currencyPayment_11: 'Account opening address',
  my_currencyPayment_12:
    'Note: Please fill in the postscript for transfer, and the postscript content is the order number.',
  my_currencyPayment_13: 'Cancel',
  my_currencyPayment_14: 'Paid',
  index_team_1: 'Our management team',
  index_team_2: 'Co-Founder, Chairman and Chief Executive Officer',
  index_team2_2: 'Chief Operating Officer',
  index_team3_2: 'Chief Strategy Officer and Head of Global Policy',
  index_team_content:"Over 20 years of experience in financial services. Focused on personal wealth management, providing personal financial planning services and asset allocation for selected clients, with experience dealing with retail, corporate and institutional investors, we look forward to creating a new platform for investors of all levels.",
  index_team2_content:"Responsible for overseeing the business development of the business. With his years of experience in business consulting, marketing, financing and business development. He has a proven track record of raising tens of millions for investments and businesses.",
  index_team3_content:"Investors in blockchain and Metaverse have investment experience in many listed companies in the Internet and technology fields, and have dabbled and deployed in the blockchain field of DEFI, NFT, Gamefi and other tracks.",
  index_cooperation_1: 'Create something extraordinary with us',
  index_cooperation_2: '18 currency exchange methods',
  index_cooperation_3: '5000+ service customers',
  index_cooperation_4: 'Across more than 30 countries',
  index_brief_1: 'Company profile',
  my_setPassportCode_6: 'Incorrect passport number format',
  my_bankCard_17: 'status：Issued  ',
  '收款钱包地址：': 'Wallet address：',
  请选择收款钱包地址: 'Please select the wallet address',
  '实际到账数量：': 'Actual receipt quantity：',
  请立即支付: 'Please pay immediately',
  '开户费用 ,便于为您开户 。':
    'Account opening fee, which is convenient for you to open an account.',
  立即支付: '立即支付',
  '币种：': '币种：',
  '公链：': '公链：',
  请选择公链: 'Please select public chain',
  '钱包地址：': 'Wallet address:',
  '收款码：': 'Collection code:',
  '哈希值：': 'Hash value:',
  取消: 'cancel',
  已支付: 'Paid',
  请选择币种: 'Please select a currency',
  立即提交: 'Submit',
  main_account_9: 'Payment through train',
  main_account_10:
    'Collect remittances from your Hong Kong multi currency account and remit the money to recipients in more than 100 countries.',
  立即开始兑换: 'Exchange now',
  '咨询类型：': 'Type of consultation:',
  请选择咨询类型: 'Please select consulting type',
  '上传附件：': 'Upload attachments:',

  ...enLocale,
  company_profiles_content:"Meta Bank is a global fintech company at the center of digital currency innovation and open financial infrastructure. At Meta, our mission is to provide financial inclusion for all, to build a bridge between the traditional financial system and the world's leading public blockchain, to open up growth paths for global companies and investors, and to contribute to the social and economic benefits of the core communities of the real economy. Contribute to efforts to remove barriers to financial access and promote economic mobility. Meta is committed to increasing financial availability, choice and opportunity for all.",
}

export default en
