import zhLocale from 'element-ui/lib/locale/lang/zh-CN' //引入element语言包
const cn = {
  header_1: '首页',
  header_2: '银行卡片',
  header_3: '关于公司',
  header_4: '常见问题',
  header_5: '登陆',
  header_6: '注册',
  header_7: '退出',
  footer_1: '我是公司名字',
  footer_2: '忠告：请务必偿还贷款, 及不要支付任何中介费用',
  footer_3: '功能',
  footer_4: '账户',
  footer_5: '汇款',
  footer_6: '货币兑换',
  footer_7: '帮助中心',
  footer_8: '联系我们',
  footer_9: '关于我们',
  footer_10: '隐私政策',
  footer_11: '资源',
  footer_12: '公司',
  footer_13: '忠告：请务必偿还贷款, 及不要支付任何中介费用',
  index_banner_1: '一个账户,通全球',
  index_banner_2:
    '一站式简化跨境支付：提供多种币种账户,商业visa卡,国际汇款和换汇',
  index_banner_3: '立即开始',
  index_banner_4:
    '非常简单的用户界面、快速打开户、低费用、服务优质、我非常推荐',
  index_banner_5: '快速、高效和专业的客户服务,极力推荐',
  index_banner_6: '领先于银行行业多年的数字金融服务',
  index_business_1: '满足各种商业务求的解决方案',
  index_business_2: '账户',
  index_business_3: '无处不在',
  index_business_4:
    '需要银行账号吗？ 只需在您的全球账户中选择一个地区,我们就会在当地合作银行为您开立一个专属于您的虚拟账户号码。 您收到的所有资金可以直接存入您的多币种钱包。',
  index_business_5: '立即申请',
  index_bank_1: '汇款',
  index_bank_2:
    '全球轻松收付款。在全国多个国家以多种货币收付款,尽显本地优势。管理账户更加灵活、快捷、优惠。',
  index_bank_3: 'Metabank卡',
  index_bank_4: '随时随地,自在消费',
  index_bank_5:
    '通过Metabank卡,使用您的全球账户资金进行网上和线下支付。在全球支持Visa的商户网络让您轻松支付任何费用。',
  index_bank_6: '立即申请',
  index_conversion_1: '货币兑换',
  index_conversion_2: '简单透明,轻松搞定',
  index_conversion_3:
    '就像在XE.com或者路透社上一样,您会获得真正的中端市场汇率。兑换货币仅需支付最优惠的费用,因此您无需花费时间比较。',
  index_conversion_4: '立即申请',
  index_issue_1: '常见问题解答',
  index_issue_2: '问题列表',
  index_issue_3: '更多',
  index_con_1: '联系我们',
  faq_1: '常见问题解答',
  faq_2: '了解更多',
  faq_3: '常见问题',
  login_1: '请输入邮箱地址',
  login_2: '请输入正确的邮箱地址',
  login_3: '邮箱',
  login_4: '请输入密码',
  login_5: '密码',
  login_6: '没有账户？立即注册账户',
  login_7: '忘记密码？',
  login_8: '登录',
  login_9: '我已阅读并同意',
  login_10: '《隐私政策》',
  login_11: '需要帮助？快联系我们',

  reg_email_1: '让我们开始吧',
  reg_email_2:
    '申请免费企业帐户的程序将需要10到15分钟。 开始后,您可以保存进度并随时回来继续申请。',
  reg_email_3:
    '申请程序应由贵公司的授权代表完成。 您将需要为所有公司董事和主要股东提供商业注册文件,个人身份证明文件和个人地址证明。',
  reg_email_4:
    '《财务条例》规定,Metabank必须符合“了解您的客户”(KYC)的要求,“反洗钱”(AML)和“反恐融资”(CFT)条例的规定。',
  reg_email_5:
    '因此,提供完整,清晰和准确的业务资料（包括财务交易）非常重要。 信息不完整或不一致可能会导致您的企业帐户申请延迟,甚至被拒绝申请。我们期待为您服务。',
  reg_email_6: '电子邮箱：',
  reg_email_7: '请输入邮箱地址',
  reg_email_8: '请输入正确的邮箱地址',
  reg_email_9: '请输入邮箱地址',
  reg_email_10: '立即提交',
  reg_email_11: '已有账号,立即登录',
  reg_email_12: '已将验证链接发送至电子邮件',
  reg_identity_1: '请选择您的注册身份',
  reg_identity_2: '中国大陆外个人用户',
  reg_identity_3: '机构用户',
  reg_identity_4: '中国大陆个人用户',
  reg_identity_5: '注：一旦注册成功,身份将不可更改',
  reg_identity_6: '继续',
  reg_identity_7: '返回',
  reg_mainLandUser_1: '个人详情',
  reg_mainLandUser_2: '您的名称：',
  reg_mainLandUser_3: '请输入您的名称',
  reg_mainLandUser_4: '请输入您的名称',
  reg_mainLandUser_5: '您的姓氏：',
  reg_mainLandUser_6: '请输入您的姓氏',
  reg_mainLandUser_7: '请输入您的姓氏',
  reg_mainLandUser_8: '手机号码：',
  reg_mainLandUser_9: '请输入您的手机号码',
  reg_mainLandUser_10: '请输入您的手机号码',
  reg_mainLandUser_11: '继续',
  reg_mainLandUser_12: '返回',
  reg_identityVerification_1: '身份验证',
  reg_identityVerification_2: '请用您的手机扫描下方二维码',
  reg_identityVerification_3: '教程',
  reg_identityVerification_4:
    '手机摄像头对准二维码如果无法运行,可以从谷歌Play或App Store下载一个二维码扫描器',
  reg_identityVerification_5: '扫描二维码的方法',
  reg_identityVerification_6: '继续',
  reg_identityVerification_7: '返回',

  reg_guidance_1: '移动会话链接到您的计算机',
  reg_guidance_2: '继续验证',
  reg_guidance_3: '再次确认',
  reg_guidance_4: '此链接由您发送 —— 如果您认为这可能是骗局,请寻求帮助',
  reg_guidance_5: '您的桌面窗口保持打开状态',
  reg_uploading_1: '护照信息上传',
  reg_uploading_2: '签发国家：',
  reg_uploading_3: '请选择签发国家',
  reg_uploading_4: '请选择签发国家',
  reg_uploading_5: '护照号码：',
  reg_uploading_6: '请输入护照号码',
  reg_uploading_7: '请输入护照号码',
  reg_uploading_8: '护照封面照：',
  reg_uploading_9: '请选择护照封面照',
  reg_uploading_10: '护照首页照：',
  reg_uploading_11: '请选择护照首页照',
  reg_uploading_12: '手持护照首页照：',
  reg_uploading_13: '请选择手持护照首页照',
  reg_uploading_14: '提交',
  reg_uploading_15: '请选择签发国家',
  reg_affirm_1: '确保卡上信息清晰可读、不模糊 、无反光',
  reg_affirm_2: '重新上传',
  reg_affirm_3: '确认',
  reg_bankInfo_1: '银行资料提交',
  reg_bankInfo_2: '地址证明(必须为3个月内发出)',
  reg_bankInfo_3: '说明',
  reg_bankInfo_4:
    '此资料为后续制作银行卡使用,有效地址证明为三个月内的住址水电账单,或房屋产权证明文件。',
  reg_bankInfo_5: '上传文件',
  reg_bankInfo_6: '支持扩展名',
  reg_bankInfo_7: '继续',
  reg_bankInfo_8: '一键同步提交',
  reg_bankInfo_9: '返回',
  reg_limit_1: '初始兑换额度',
  reg_limit_2: '您目前的身份为',
  reg_limit_3: '您的初始年度总兑换额度为',
  reg_limit_4: '如需申请额外额度,请点击“额外申请”',
  reg_limit_5: '继续',
  reg_limit_6: '返回',

  reg_setPassword_1: '设置密码',
  reg_setPassword_2: '您的密码长度至少应为八(8)位数,并包含至少一(1)个以下字符',
  reg_setPassword_3: '大写字母',
  reg_setPassword_4: '小写字母',
  reg_setPassword_5: '数字',
  reg_setPassword_6: '特殊字符',
  reg_setPassword_7: '密码',
  reg_setPassword_8: '请输入密码',
  reg_setPassword_9: '确认密码',
  reg_setPassword_10: '请再次输入密码',
  reg_setPassword_11: '继续',
  reg_setPassword_12: '返回',
  reg_setPassword_13: '已有账号,立即登录',
  reg_setPassword_14: '请输入密码',
  reg_setPassword_15: '密码格式不正确',
  reg_setPassword_16: '请再次输入密码',
  reg_setPassword_17: '两次输入密码不一致',
  reg_setPayPass_1: '设置支付密码',
  reg_setPayPass_2: '您的密码长度至少应为八(8)位数,并包含至少一(1)个以下字符',
  reg_setPayPass_3: '大写字母',
  reg_setPayPass_4: '小写字母',
  reg_setPayPass_5: '数字',
  reg_setPayPass_6: '特殊字符',
  reg_setPayPass_7: '支付密码',
  reg_setPayPass_8: '请输入密码',
  reg_setPayPass_9: '确认密码',
  reg_setPayPass_10: '请再次输入密码',
  reg_setPayPass_11: '继续',
  reg_setPayPass_12: '返回',
  reg_applySuccess_1: '申请成功',
  reg_applySuccess_2: '请立即支付150U开户费用 ,便于为您开户 。',
  reg_applySuccess_3: '立即支付',
  reg_applySuccess_4: '请等待工作人员审核',
  reg_applySuccess_5: '立即支付',
  reg_applySuccess_6: '钱包地址：',
  reg_applySuccess_7: '收款码：',
  reg_applySuccess_8: '哈希值：',
  reg_applySuccess_9: '请输入付款哈希值信息',
  reg_applySuccess_10: '请输入付款哈希值信息',
  reg_applySuccess_11:
    '请使用自己的钱包转账,此付款钱包将自动保存为您的常用钱包地址。',
  reg_applySuccess_12: '取消',
  reg_applySuccess_13: '已支付',
  reg_findPassword_1: '找回密码',
  reg_findPassword_2: '您的密码长度至少应为八(8)位数,并包含至少一(1)个以下字符',
  reg_findPassword_3: '大写字母',
  reg_findPassword_4: '小写字母',
  reg_findPassword_5: '数字',
  reg_findPassword_6: '特殊字符',
  reg_findPassword_7: '密码',
  reg_findPassword_8: '请输入密码',
  reg_findPassword_9: '确认密码',
  reg_findPassword_10: '请再次输入密码',
  reg_findPassword_11: '立即重置',
  reg_forgetPassword_1: '忘记密码',
  reg_forgetPassword_2: '我们将通过电子邮箱向您发送重置密码说明',
  reg_forgetPassword_3: '电子邮箱：',
  reg_forgetPassword_4: '请输入邮箱地址',
  reg_forgetPassword_5: '请输入正确的邮箱地址',
  reg_forgetPassword_6: '请输入邮箱地址',
  reg_forgetPassword_7: '立即提交',
  reg_forgetPassword_8: '返回',
  reg_forgetPassword_9: '已将验证链接发送电子邮件至',
  reg_organizationUser_1: '机构详情',
  reg_organizationUser_2: '机构名称：',
  reg_organizationUser_3: '请输入机构名称',
  reg_organizationUser_4: '请输入机构名称',
  reg_organizationUser_5: '法人名称：',
  reg_organizationUser_6: '请输入法人名称',
  reg_organizationUser_7: '请输入法人名称',
  reg_organizationUser_8: '法人姓氏：',
  reg_organizationUser_9: '请输入法人姓氏',
  reg_organizationUser_10: '请输入法人姓氏',
  reg_organizationUser_11: '联系方式：',
  reg_organizationUser_12: '请输入您的手机号码',
  reg_organizationUser_13: '请输入您的手机号码',
  reg_organizationUser_14: '机构注册页',
  reg_organizationUser_15: '说明',
  reg_organizationUser_16: '最近3个月内的水电费或手机/银行卡/银行结单。',
  reg_organizationUser_17: '上传文件',
  reg_organizationUser_18: '支持扩展名',
  reg_organizationUser_19: '继续',
  reg_organizationUser_20: '返回',
  reg_overseasUser_1: '海外注册详情',
  reg_overseasUser_2: '您的名称：',
  reg_overseasUser_3: '请输入您的名称',
  reg_overseasUser_4: '请输入您的名称',
  reg_overseasUser_5: '您的姓氏：',
  reg_overseasUser_6: '请输入您的姓氏',
  reg_overseasUser_7: '请输入您的姓氏',
  reg_overseasUser_8: '手机号码：',
  reg_overseasUser_9: '请输入您的手机号码',
  reg_overseasUser_10: '请选择',
  reg_overseasUser_11: '请输入您的手机号码',
  reg_overseasUser_12: '地址证明(必须为3个月内发出)',
  reg_overseasUser_13: '说明',
  reg_overseasUser_14: '最近3个月内的水电费或手机/银行卡/银行结单。',
  reg_overseasUser_15: '上传文件',
  reg_overseasUser_16: '支持扩展名',
  reg_overseasUser_17: '继续',
  reg_overseasUser_18: '返回',

  main_account_1: '跨境商户账户',
  main_account_2: '注册',
  main_account_3: '以更轻松便捷的方式收付款和兑换外币。',
  main_account_4:
    '全球轻松收付款。 在全球多个国家以多种货币收付款,始终拥有本地优势。此外,您还可以享受真正的跨境交易零汇损和使用Visa卡,以满足扩展全球业务的一切需求。',
  main_account_5: '外币帐户让您随时走向国际。',
  main_account_6:
    '在香港、澳大利亚、加拿大、欧洲、印尼、日本、墨西哥、新加坡、英国和美国开设银行账号,我们还将增加更多国家。',
  main_account_7: '新一代金融平台。',
  main_account_8:
    '可通过网络和应用程序实现全天候访问,获得支付和信贷引擎的强大支持,收费透明相宜,是您收款、兑换外币和转账的不二选择。',
  main_payment_1: '无论在何地营商都可享本地待遇。',
  main_payment_2: '注册',
  main_payment_3: '数字经济支付方式。',
  main_payment_4:
    '如今,企业可轻松实现全球买卖交易,因此,我们必须让企业能够同样轻松实现全球收付款。使用全球帐户进行支付,让您能够在所选择的国家开设新的外币帐户,并为您提供专属的银行账号。',
  main_payment_5: '更实惠,更快捷,更安全。',
  main_payment_6:
    '我们支持多达14种收款币种和18种付款币种。无缝跨境支付,不收取代理行费用。此外,您还可以享受真正的中间价。',
  main_payment_7: '真正实现“覆盖全球”。',
  main_payment_8:
    '通过覆盖30多个国家的虚拟帐户可实现以美元、英镑、欧元和其他国际货币收款。将帐户与亚马逊和Shopify等电商平台连接,或与Stripe和PayPal等第三方支付连接。',
  main_payment_9: '支付直通车。',
  main_payment_10:
    '以您的香港多币种帐户收取汇款,并将款项汇给100多个国家的收款人。',
  main_card_1: '为您带来极尽灵活的理财体验。',
  main_card_2: '立即注册',
  main_card_3: '您的安心之选。',
  main_card_4: '即时批核',
  main_card_5:
    '仅需点击 6 次,全球账户客户即可获得一张新的虚拟卡！立即开始在线消费。',
  main_card_6: '高度安全',
  main_card_7:
    '付款卡的敏感信息将安全地存储在Currenxie应用程序中。 只有您可以获取这些信息。',
  main_card_8: '无忧用卡',
  main_card_9:
    '无卡号的实体卡可防止被盗用。您可随时地更改交易密码。只需一键即可冻结您的卡。',
  main_exchange_1: '实时国际汇兑。',
  main_exchange_2: '立即注册',
  main_exchange_3: '更具竞争力的价格。',
  main_exchange_4:
    '我们的网上服务让您享受实时国际汇兑汇率,与传统银行相比,为您节省8倍以上开支。',
  main_exchange_5: '即期合约。',
  main_exchange_6:
    '当您需要立即进行国际支付时,即期合约会提供实时批发汇率,而您的收款方会在当天或第二天收到相应金额。',
  main_exchange_7: '远期合约。',
  main_exchange_8:
    '对于希望保护利润率和管理外汇风险的企业,最多可以提前一年锁定汇率。',
  main_contact_1: '联系我们',
  main_contact_2: '您的名字:',
  main_contact_3: '请输入您的名字',
  main_contact_4: '请输入您的名字',
  main_contact_5: '您的姓氏：',
  main_contact_6: '请输入您的姓氏',
  main_contact_7: '请输入您的姓氏',
  main_contact_8: '您的公司名字：',
  main_contact_9: '请输入您的公司名字',
  main_contact_10: '请输入您的公司名字',
  main_contact_11: '您的邮箱地址：',
  main_contact_12: '请输入您的邮箱地址',
  main_contact_13: '请输入您的邮箱地址',
  main_contact_14: '具体问题描述：',
  main_contact_15: '请输入具体问题描述',
  main_contact_16: '提交',
  main_contact_17: '欢迎联系我们',
  main_contact_18: '了解更多',

  my_walletAddr_1: '钱包地址',
  my_walletAddr_2: '新增钱包地址',
  my_walletAddr_3: '数字货币类型',
  my_walletAddr_4: '地址',
  my_walletAddr_5: '操作',
  my_walletAddr_6: '已使用',
  my_walletAddr_7: '确认删除钱包地址？',
  my_setPayPassword_1: '修改支付密码',
  my_setPayPassword_2: '您的密码长度至少应为8位数,并包含至少1个以下字符',
  my_setPayPassword_3: '大写字母',
  my_setPayPassword_4: '小写字母',
  my_setPayPassword_5: '数字',
  my_setPayPassword_6: '特殊字符',
  my_setPayPassword_7: '新支付密码：',
  my_setPayPassword_8: '请输入新支付密码',
  my_setPayPassword_9: '重复支付密码：',
  my_setPayPassword_10: '请再次输入新支付密码',
  my_setPayPassword_11: '取消',
  my_setPayPassword_12: '保存',
  my_setPayPassword_13: '请输入密码',
  my_setPayPassword_14: '密码格式不正确',
  my_setPayPassword_15: '请再次输入密码',
  my_setPayPassword_16: '两次输入密码不一致！',
  my_setPassword_1: '修改密码',
  my_setPassword_2: '您的密码长度至少应为八(8)位数,并包含至少一(1)个以下字符',
  my_setPassword_3: '大写字母',
  my_setPassword_4: '小写字母',
  my_setPassword_5: '数字',
  my_setPassword_6: '特殊字符',
  my_setPassword_7: '新密码：',
  my_setPassword_8: '请输入新密码',
  my_setPassword_9: '重复新密码：',
  my_setPassword_10: '请再次输入新密码',
  my_setPassword_11: '取消',
  my_setPassword_12: '保存',
  my_setPassword_13: '请输入密码',
  my_setPassword_14: '密码格式不正确',
  my_setPassword_15: '请再次输入密码',
  my_setPassword_16: '两次输入密码不一致！',
  my_setPassportCode_1: '修改护照号码',
  my_setPassportCode_2: '护照号码：',
  my_setPassportCode_3: '请输入护照号码',
  my_setPassportCode_4: '取消',
  my_setPassportCode_5: '保存',
  my_setPassportCode_6: '护照号码格式不正确',
  my_setMobile_1: '修改手机号',
  my_setMobile_2: '手机号：',
  my_setMobile_3: '请输入新手机号',
  my_setMobile_4: '取消',
  my_setMobile_5: '保存',
  my_safety_1: '安全设置',
  my_safety_2: '登陆密码',
  my_safety_3: '请输入密码',
  my_safety_4: '修改',
  my_safety_5: '支付密码',
  my_safety_6: '请输入密码',
  my_safety_7: '修改',
  my_logBinding_1: '安全登陆绑定',
  my_logBinding_2: 'Google验证',
  my_logBinding_3: '请输入手机号',
  my_logBinding_4: '修改',
  my_logBinding_5: '邮箱验证',
  my_datum_1: '个人资料',
  my_datum_2: '姓名',
  my_datum_3: '手机号',
  my_datum_4: '请输入手机号',
  my_datum_5: '修改',
  my_datum_6: '签发国家',
  my_datum_7: '护照号码',
  my_datum_8: '请输入护照号码',
  my_datum_9: '修改',
  my_datum_10: '护照照片',
  my_addWalletAddr_1: '钱包地址管理',
  my_addWalletAddr_2: '币种：',
  my_addWalletAddr_3: '请选择币种',
  my_addWalletAddr_4: '钱包地址：',
  my_addWalletAddr_5: '请输入钱包地址',
  my_addWalletAddr_6: '取消',
  my_addWalletAddr_7: '保存',
  my_addWalletAddr_8: '请选择币种',
  my_addWalletAddr_9: '请输入地址',
  my_record_1: '申请记录',
  my_record_2: '申请时间',
  my_record_3: '计划申请额度',
  my_record_4: '申请手续费',
  my_record_5: '状态',
  my_record_6: '未处理',
  my_record_7: '已处理',
  my_record_8: '驳回',
  my_record_9: '确认',
  my_limit_1: '兑换额度',
  my_limit_2: '年度总额度',
  my_limit_3: '额度使用',
  my_limit_4: '剩余：',
  my_limit_5: '申请额度',
  my_limit_6: '额度不够？点击立即申请',
  my_limit_7: '申请记录',
  my_limit_8: '货币兑换',
  my_limit_9: '已使用',
  my_nav_1: '个人信息',
  my_nav_2: '我的额度',
  my_nav_3: '钱包地址',
  my_nav_4: '银行卡管理',
  my_nav_5: '邀请注册',
  my_selectAddr_1: '收件地址管理',
  my_selectAddr_2: '默认',
  my_selectAddr_3: '新增收件地址',
  my_bankCardDetail_1: '银行卡详情',
  my_bankCardDetail_2: '姓名（中文）：',
  my_bankCardDetail_3: '姓名（英文）：',
  my_bankCardDetail_4: '卡号：',
  my_bankCardDetail_5: '银行全称（中文）：',
  my_bankCardDetail_6: '银行全称（英文）：',
  my_bankCardDetail_7: 'SWIFT代码:',
  my_bankCardDetail_8: '银行地址：',
  my_bankCardDetail_9: '联系地址：',
  my_bankCardDetail_10: '联系电话：',
  my_bankCardDetail_11: '邮编：',
  my_bankCardDetail_12: '关闭',
  my_bankCard_1: '银行卡管理',
  my_bankCard_2: '新增',
  my_bankCard_3: '卡号',
  my_bankCard_4: '状态：已申请',
  my_bankCard_5: '状态：制作中',
  my_bankCard_6: '查看详情',
  my_bankCard_7: '申请实体卡',
  my_bankCard_8: '取消申请',
  my_bankCard_9: '确认要删除此银行卡吗',
  my_bankCard_10: '提示',
  my_bankCard_11: '确定',
  my_bankCard_12: '取消',
  my_bankCard_13: '确认要取消申请实体卡吗?',
  my_bankCard_14: '提示',
  my_bankCard_15: '确定',
  my_bankCard_16: '取消',
  my_applyRealCard_1: '申请实体卡',
  my_applyRealCard_2: '姓名（中文）：',
  my_applyRealCard_3: '姓名（英文）：',
  my_applyRealCard_4: '卡号：',
  my_applyRealCard_5: '银行全称（中文）：',
  my_applyRealCard_6: '银行全称（英文）：',
  my_applyRealCard_7: '邮寄地址：',
  my_applyRealCard_8: '制作费：',
  my_applyRealCard_9: '邮费：',
  my_applyRealCard_10: '取消',
  my_applyRealCard_11: '立即申请',
  my_addBankCard_1: '银行卡',
  my_addBankCard_2: '姓名（中文）：',
  my_addBankCard_3: '请输入姓名',
  my_addBankCard_4: '姓名（英文）：',
  my_addBankCard_5: '请输入姓名',
  my_addBankCard_6: '卡号：',
  my_addBankCard_7: '请输入卡号',
  my_addBankCard_8: '银行全称（中文）：',
  my_addBankCard_9: '请输入银行全称',
  my_addBankCard_10: '银行全称（英文）：',
  my_addBankCard_11: '请输入银行全称',
  my_addBankCard_12: 'SWIFT代码:',
  my_addBankCard_13: '请输入SWIFT代码',
  my_addBankCard_14: '银行地址：',
  my_addBankCard_15: '请输入银行地址',
  my_addBankCard_16: '联系地址：',
  my_addBankCard_17: '请输入联系地址',
  my_addBankCard_18: '联系电话：',
  my_addBankCard_19: '请输入联系电话',
  my_addBankCard_20: '邮编：',
  my_addBankCard_21: '请输入邮编',
  my_addBankCard_22: '取消',
  my_addBankCard_23: '保存',
  my_addBankCard_24: '请输入卡号',
  my_addBankCard_25: '请输入SWIFT代码',
  my_addBankCard_26: '请输入银行地址',
  my_addBankCard_27: '请输入联系地址',
  my_addBankCard_28: '请输入联系电话',
  my_addBankCard_29: '请输入邮编',
  my_addAddr_1: '新增收货地址',
  my_addAddr_2: '收货人姓名：',
  my_addAddr_3: '请输入收货人姓名',
  my_addAddr_4: '请输入收货人姓名',
  my_addAddr_5: '手机号：',
  my_addAddr_6: '请输入手机号',
  my_addAddr_7: '请输入手机号',
  my_addAddr_8: '请选择国家',
  my_addAddr_9: '国家：',
  my_addAddr_10: '请选择国家',
  my_addAddr_11: '请输入省市县信息',
  my_addAddr_12: '省市县：',
  my_addAddr_13: '请输入省市县信息',
  my_addAddr_14: '请输入街道、小区门牌等详细地址',
  my_addAddr_15: '详细地址：',
  my_addAddr_16: '请输入街道、小区门牌等详细地址',
  my_addAddr_17: '邮政编码：',
  my_addAddr_18: '请输入邮政编码',
  my_addAddr_19: '设为默认地址',
  my_addAddr_20: '取消',
  my_addAddr_21: '保存',
  my_usdPayment_1: '立即支付',
  my_usdPayment_2: '钱包地址：',
  my_usdPayment_3: '收款码：',
  my_usdPayment_4: '哈希值：',
  my_usdPayment_5: '请输入付款哈希值信息',
  my_usdPayment_6: '请输入付款哈希值信息',
  my_usdPayment_7: '必须使用平台内登记地址转账,否则转账将会丢失。',
  my_usdPayment_8: '如未登记,请点击',
  my_usdPayment_9: '地址登记',
  my_usdPayment_10: '取消',
  my_usdPayment_11: '已支付',
  my_conversion_1: '货币兑换',
  my_conversion_2: '选择兑换方式：',
  my_conversion_3: '兑换为美金',
  my_conversion_4: '兑换为数字货币',
  my_conversion_5: '选择币种：',
  my_conversion_6: '请选择您要使用的币种',
  my_conversion_7: '请选择您要使用的币种',
  my_conversion_8: '当前币种汇率',
  my_conversion_9: '美元',
  my_conversion_10: '选择公链：',
  my_conversion_11: '请选择公链',
  my_conversion_12: '收款美金账户：',
  my_conversion_13: '请选择收款账户',
  my_conversion_14: '请选择收款美金账户',
  my_conversion_15: '兑换数量：',
  my_conversion_16: '请输入您要兑换的币种数量',
  my_conversion_17: '请输入您要兑换的币种数量',
  my_conversion_18: '实际到账金额：',
  my_conversion_19: '手续费金额：',
  my_conversion_20: '当前剩余兑换额度',
  my_conversion_21: '您当前已超额申请兑换,请点击',
  my_conversion_22: '申请更多额度',
  my_conversion_23: '立即兑换',
  my_conversion_24: '已提交此次申请,申请通过将以邮件形式通知您！',
  my_conversion_25: '提示',
  my_conversion_26: '确定',
  my_conversion_27: '请先选择您要使用的币种',
  my_enhance_1: '兑换额度申请',
  my_enhance_2: '年度总额度：',
  my_enhance_3: '当前剩余额度：',
  my_enhance_4: '计划申请额度：',
  my_enhance_5: '请输入您要申请的额度',
  my_enhance_6: '请输入您要申请的额度',
  my_enhance_7: '申请手续费：',
  my_enhance_8: '立即兑换',
  my_enhance_9: '已提交此次申请,申请通过将以邮件形式通知您！',
  my_enhance_10: '提示',
  my_enhance_11: '确定',
  my_currencyPayment_1: '汇款银行：',
  my_currencyPayment_2: '汇款银行',
  my_currencyPayment_3: '收款账户',
  my_currencyPayment_4: '收款账户联系地址：',
  my_currencyPayment_5: '收款账户联系地址',
  my_currencyPayment_6: '开户行名称：',
  my_currencyPayment_7: '开户行名称',
  my_currencyPayment_8: 'SWIFT代码:',
  my_currencyPayment_9: 'SWIFT代码',
  my_currencyPayment_10: '开户行地址：',
  my_currencyPayment_11: '开户行地址',
  my_currencyPayment_12: '注意：转账请填写附言,附言内容为订单号。',
  my_currencyPayment_13: '取消',
  my_currencyPayment_14: '已支付',
  index_team_1: '我们的管理团队',
  index_team_2: '联合创始人、董事长兼首席执行官',
  index_team2_2: '首席运营官官',
  index_team3_2: '首席战略官兼全球政策负责人',
  index_team_content:"拥有金融服务20多年的经验。专注于个人财富管理，提供个人理财规划服务和选定客户的资产配置，凭借处理散户、企业和机构投资者的经验，期待为所有级别的投资者打造一个全新平台。",
  index_team2_content:"负责监督业务的业务发展。凭借他在商业咨询、营销、融资和业务发展方面的多年经验。他拥有经过验证的为投资和企业筹集数千万资金的记录。",
  index_team3_content:"区块链和元宇宙投资人，对互联网和科技领域的多家上市公司拥有投资经验，DEFI、NFT、Gamefi等多个赛道的区块链领域均有所涉猎与布局。",
  index_cooperation_1: '与我们一起创造不凡',
  index_cooperation_2: '18种货币兑换方式',
  index_cooperation_3: '5000+服务客户',
  index_cooperation_4: '横跨30多个国家',
  index_brief_1: '企业简介',
  my_bankCard_17: '状态：已发放',
  '收款钱包地址：': '收款钱包地址：',
  请选择收款钱包地址: '请选择收款钱包地址',
  '实际到账数量：': '实际到账数量：',
  请立即支付: '请立即支付',
  '开户费用 ,便于为您开户 。': '开户费用 ,便于为您开户 。',
  立即支付: '立即支付',
  '币种：': '币种：',
  '公链：': '公链：',
  请选择公链: '请选择公链',
  '钱包地址：': '钱包地址：',
  '收款码：': '收款码：',
  '哈希值：': '哈希值：',
  取消: '取消',
  已支付: '已支付',
  请选择币种: '请选择币种',
  立即提交: '立即提交',
  main_account_9: '支付直通车',
  main_account_10:
    '以您的香港多币种帐户收取汇款，并将款项汇给100多个国家的收款人。',
  立即开始兑换: '立即开始兑换',
  '咨询类型：': '咨询类型：',
  请选择咨询类型: '请选择咨询类型',
  '上传附件：': '上传附件：',

  ...zhLocale,
  
  company_profiles_content:'Meta Bank是一家全球金融科技公司，处于数字货币创新和开放金融基础设施的中心。在 Meta，我们的使命是全民普惠金融，架起传统金融系统和世界领先的公共区块链的桥梁，为全球企业和投资者开启增长之路,为实体经济核心社区的社会和经济效益做出贡献，努力消除金融准入的障碍并促进经济流动性。Meta 致力于为所有人增加财务可用性、选择和机会。'
  
}

export default cn
