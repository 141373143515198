import service from '@/utils/request'
import router from '@/router'
import dayjs from 'dayjs'

/**
 * 深拷贝
 * @param {*} obj
 */
export const clone = (obj) => {
  let objClone = Array.isArray(obj) ? [] : {}
  if (obj && typeof obj === 'object') {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (obj[key] && typeof obj[key] === 'object') {
          objClone[key] = clone(obj[key])
        } else {
          objClone[key] = obj[key]
        }
      }
    }
  }
  return objClone
}

// 获取url
export const getUrlArgs = (name) => {
  return (
    decodeURIComponent(
      (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
        location.href
      ) || ['', ''])[1].replace(/\+/g, '%20')
    ) || null
  )
}

/**
 * @param {str} str
 * @Desc 返回年月日
 */
export let ruleTimeYear = (str) => {
  if (str) {
    return dayjs(str).format('YYYY-MM-DD HH:mm:ss')
  } else {
    return ''
  }
}

// 是否登录
export let isLogin = () => {
  if (localStorage.getItem('sm_token')) {
    return true
  } else {
    return false
  }
}

// 退出登录
export const loginout = async () => {
  let res = await service.post('/api/User/logout')
  if (res.code === 1) {
    localStorage.removeItem('sm_token')
    localStorage.removeItem('sm_email')
    localStorage.removeItem('sm_type')
    localStorage.removeItem('sm_userinfo')
    router.push({ name: 'Login' })
  }
}
